import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
    AccountCircle,
    ArrowBack,
    House,
    HouseOutlined,
    HubOutlined,
    Logout,
    PieChartOutlined,
    PriceChangeOutlined,
    Campaign,
    Update,
    DarkMode,
    LightMode,
    PeopleAltOutlined,
    ContactsOutlined,
} from '@mui/icons-material';

import AppBar from '../AppBar/AppBar';
import Drawer from '../Drawer/Drawer';
import { isAdmin } from '../../../utils/utilityFunctions';
import { logout } from '../../../store/ducks/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useBeforeunload } from 'react-beforeunload';
import { ColorModeContext } from '../../../context/ColorModeContext';

export default function Layout({ children }) {
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const buildings = useSelector((state) => state.buildings.buildings);
    const admin = isAdmin(user);
    const { mode, toggleColorMode } = useContext(ColorModeContext);
    const headerTitle = useMemo(
        () =>
            location.pathname === '/building' || location.pathname === '/edit-building'
                ? (
                      (admin ? buildings : user.buildings).find(
                          (b) => b.source === new URLSearchParams(location.search).get('source')
                      ) || {
                          name: 'Új Épület Hozzáadása',
                      }
                  ).name
                : navigationObject[location.pathname],
        [user.buildings, location, buildings, admin]
    );
    useBeforeunload(() => {
        localStorage.setItem('path', `${location.pathname}${location.search}`);
    });

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const mainListItems = (
        <>
            <ListItemButton onClick={() => navigate('/')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Irányítópult" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/user')}>
                <ListItemIcon>
                    <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Személyes adatok" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/buildings')}>
                <ListItemIcon>
                    <House />
                </ListItemIcon>
                <ListItemText primary="Épületek" />
            </ListItemButton>
            <ListItemButton
                onClick={() => {
                    localStorage.removeItem('token');
                    dispatch(logout());
                }}
            >
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary="Kijelentkezés" />
            </ListItemButton>
        </>
    );

    const secondaryListItems = (
        <>
            <ListSubheader component="div" inset>
                Admin funkciók
            </ListSubheader>
            <ListItemButton onClick={() => navigate('/users')}>
                <ListItemIcon>
                    <PeopleAltOutlined />
                </ListItemIcon>
                <ListItemText primary="Felhasználók" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/admin-buildings')}>
                <ListItemIcon>
                    <HouseOutlined />
                </ListItemIcon>
                <ListItemText primary="Épületek (összes)" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/announcements')}>
                <ListItemIcon>
                    <Campaign />
                </ListItemIcon>
                <ListItemText primary="Közlemények" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/updates')}>
                <ListItemIcon>
                    <Update />
                </ListItemIcon>
                <ListItemText primary="Frissítések" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/nective-hubs')}>
                <ListItemIcon>
                    <HubOutlined />
                </ListItemIcon>
                <ListItemText primary="Nective hubok" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/contacts')}>
                <ListItemIcon>
                    <ContactsOutlined />
                </ListItemIcon>
                <ListItemText primary="Kontaktok" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/pricelist')}>
                <ListItemIcon>
                    <PriceChangeOutlined />
                </ListItemIcon>
                <ListItemText primary="Árlista" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate('/statistics')}>
                <ListItemIcon>
                    <PieChartOutlined />
                </ListItemIcon>
                <ListItemText primary="Statisztikák" />
            </ListItemButton>
        </>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" color="primary" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {location.pathname !== '/' && (
                        <Tooltip title="Vissza az előző oldalra">
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => navigate(-1)}
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        {headerTitle}
                    </Typography>
                    <IconButton color="inherit" onClick={() => navigate('/notifications')}>
                        <Badge badgeContent={user.sentNotifications?.filter((n) => !n.read)?.length} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Tooltip title={mode === 'dark' ? 'Világos mód' : 'Sötét mód'}>
                        <IconButton color="inherit" onClick={toggleColorMode}>
                            {mode === 'dark' ? <LightMode /> : <DarkMode />}
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: [1],
                    }}
                >
                    <img
                        style={{ paddingLeft: 12 }}
                        height={30}
                        src={mode === 'light' ? 'nective-logo.png' : 'nective_web_white_logo.png'}
                        alt="nective icon"
                    />
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {mainListItems}
                    <Divider sx={{ my: 1 }} />
                    {admin && secondaryListItems}
                </List>
            </Drawer>
            {children}
        </Box>
    );
}

const navigationObject = {
    '/': 'Irányítópult',
    '/buildings': 'Épületek',
    '/admin-buildings': 'Épületek (összes)',
    '/announcements': 'Közlemények',
    '/updates': 'Frissítések',
    '/statistics': 'Statisztikák',
    '/pricelist': 'Árlista',
    '/contacts': 'Kontaktok',
    '/notifications': 'Értesítések',
    '/user': 'Személyes adatok',
    '/nective-hubs': 'Nective Hubok',
    '/change-password-user': 'Jelszó módosítás',
};
