import { BUILDING_SOURCE_REGEXP, NECTIVE_HUB_SOURCE_REGEXP } from './constants';

export const validator = (type, value, state, mainModuleType) => {
    switch (type) {
        case 'email':
            return !emailValidator(value);
        case 'name':
        case 'titleHu':
            return value.length > 50 || value.trim().length < 2;
        case 'titleEn':
        case 'titleDe':
            return value.length > 50;
        case 'address':
            return value.length > 100 || value.trim().length < 5;
        case 'city':
            return value.length > 100 || value.trim().length < 3;
        case 'source':
            return mainModuleType === 'LAN'
                ? value.trim().length < 23 || !BUILDING_SOURCE_REGEXP.test(value)
                : value.trim().length < 16 || !NECTIVE_HUB_SOURCE_REGEXP.test(value);
        case 'password':
            return !/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(
                value
            );
        case 'repassword':
            return value !== state.password.value;
        case 'announcementType':
        case 'descriptionHu':
            return value.length > 500 || value.trim().length < 2;
        case 'descriptionEn':
        case 'descriptionDe':
        case 'link':
            return value.length > 500;

        default:
            return false;
    }
};

const emailValidator = (value) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    ) && value.length > 1;
