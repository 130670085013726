import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getContacts = createAsyncThunk('contacts/getContacts', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/contacts');
        return { contacts: response.data };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a Kontaktok lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const removeContact = createAsyncThunk('contacts/removeContact', async (id, thunkAPI) => {
    try {
        await apiAxios.delete(`/admin/contacts/${id}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen törölted a kontaktot a rendszerből',
            })
        );
        thunkAPI.dispatch(getContacts());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Kontakt törlése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const updateContact = createAsyncThunk('contacts/updateContact', async (contact, thunkAPI) => {
    try {
        await apiAxios.put(`/admin/contacts/${contact.id}`, contact);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen módosítottad a kontaktot',
            })
        );
        thunkAPI.dispatch(getContacts());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Kontakt modósítása sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    error: null,
    loading: false,
    contacts: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getContacts.fulfilled, (state, { payload }) => updateState(state, { contacts: payload.contacts }))
        .addMatcher(
            (action) => action.type.startsWith('contacts') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('contacts') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('contacts') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
