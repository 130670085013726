import React from 'react';

import Table from '../../UI/Table/Table';

function BuildingUsersTable({ users, ...tableProps }) {
    const columns = [
        {
            title: 'Email',
            field: 'email',
        },
        { title: 'Főfelhasználó', field: 'mainUser', type: 'boolean', editable: 'never' },
        { title: 'Kitiltva', field: 'banned', type: 'boolean', editable: 'never' },
    ];
    return <Table title="Felhasználók" columns={columns} data={users} {...tableProps} />;
}
export default BuildingUsersTable;
