import React, { useRef, useState } from 'react';
import { Box, TextField } from '@mui/material';

const inputStyle = {
    width: 40,
    marginRight: 2,
    'input::WebkitOuterSpinButton': 'none',
    'input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    'input[type=number]': {
        MozAppearance: 'textfield',
    },
};

export default function SixDigitInput({ finalValue }) {
    const [state, setState] = useState(['', '', '', '', '', '']);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);

    const refArray = [ref1, ref2, ref3, ref4, ref5, ref6];

    const handleChange = (value, index) => {
        const digitArray = [...state];
        digitArray[index] = value.toString().length > 1 ? value.toString()[1] : value;

        setState(digitArray);
        if (index <= 4) {
            refArray[index + 1].current.focus();
        }
        if (!digitArray.some((v) => v.length === 0)) {
            finalValue(digitArray.join('').toString());
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            if (index > 0 && index < 5) {
                event.preventDefault();
                handleChange('', index - 1);
                refArray[index - 1].current.focus();
            }
            if (index === 5) {
                event.preventDefault();
                const digitArray = [...state];
                digitArray[index] = '';
                digitArray[index - 1] = '';
                setState(digitArray);
                refArray[index - 1].current.focus();
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', p: 4 }}>
            <TextField
                inputRef={ref1}
                tabIndex={0}
                variant="outlined"
                sx={inputStyle}
                type="number"
                autoFocus
                value={state[0]}
                onChange={(event) => handleChange(event.target.value, 0)}
                onKeyDown={(event) => handleKeyDown(event, 0)}
            />
            <TextField
                inputRef={ref2}
                inputProps={{ maxLength: 1 }}
                tabIndex={1}
                variant="outlined"
                sx={inputStyle}
                type="number"
                value={state[1]}
                onChange={(event) => handleChange(event.target.value, 1)}
                onKeyDown={(event) => handleKeyDown(event, 1)}
            />
            <TextField
                inputRef={ref3}
                tabIndex={2}
                variant="outlined"
                sx={inputStyle}
                type="number"
                value={state[2]}
                onChange={(event) => handleChange(event.target.value, 2)}
                onKeyDown={(event) => handleKeyDown(event, 2)}
            />
            <TextField
                inputRef={ref4}
                tabIndex={3}
                variant="outlined"
                sx={inputStyle}
                type="number"
                value={state[3]}
                onChange={(event) => handleChange(event.target.value, 3)}
                onKeyDown={(event) => handleKeyDown(event, 3)}
            />
            <TextField
                inputRef={ref5}
                tabIndex={4}
                variant="outlined"
                sx={inputStyle}
                type="number"
                value={state[4]}
                onChange={(event) => handleChange(event.target.value, 4)}
                onKeyDown={(event) => handleKeyDown(event, 4)}
            />
            <TextField
                inputRef={ref6}
                tabIndex={5}
                variant="outlined"
                sx={inputStyle}
                type="number"
                value={state[5]}
                onChange={(event) => handleChange(event.target.value, 5)}
                onKeyDown={(event) => handleKeyDown(event, 5)}
            />
        </Box>
    );
}
