import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const clearUpdateState = createAction('buildings/clearUpdateState');
export const buildingUpdateStart = createAction('buildings/buildingUpdateStart');
export const buildingUpdateSuccess = createAction('buildlings/buildingUpdateSuccess');
export const buildingUpdateFailed = createAction('buildlings/buildingUpdateFailed', (errorMessage) => ({
    payload: { errorMessage },
}));

export const buildingUpdateState = createAction('buildings/buildingUpdateState', (updateState) => ({
    payload: { updateState },
}));

export const getBuildings = createAsyncThunk('buildings/getBuildings', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/buildings');
        return { buildings: response.data };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az épületek lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const getUpdatesBySource = createAsyncThunk('buildings/getUpdatesBySource', async (source, thunkAPI) => {
    try {
        const response = await apiAxios.get(`/building/updates/${source}`);
        const updatesAvailable = response.data;
        return { updatesAvailable };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az épület elérhető frissítéseinek lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const getCollectMetricsInfo = createAsyncThunk('buildings/getCollectMetricsInfo', async (source, thunkAPI) => {
    try {
        const response = await apiAxios.get(`/admin/collect-metrics/${source}`);
        return { collectMetrics: response?.data?.collectMetrics, cycle: response?.data?.cycle };
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const setCollectMetricsInfo = createAsyncThunk(
    'buildings/setCollectMetricsInfo',
    async ({ source, collectMetricsInfo }, thunkAPI) => {
        try {
            await apiAxios.post(`/admin/collect-metrics/${source}`, collectMetricsInfo);
            thunkAPI.dispatch(getCollectMetricsInfo(source));
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

const initialState = {
    error: null,
    loading: false,
    updating: false,
    updateState: null,
    updateSuccess: false,
    collectMetrics: false,
    cycle: '15',
    updatesAvailable: [],
    buildings: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(buildingUpdateStart, (state) => updateState(state, { updating: true, error: null }))
        .addCase(buildingUpdateSuccess, (state) => updateState(state, { updateSuccess: true, updating: false }))
        .addCase(buildingUpdateFailed, (state, { payload }) =>
            updateState(state, { updateSuccess: false, error: payload.errorMessage, updating: false })
        )
        .addCase(buildingUpdateState, (state, { payload }) => updateState(state, { updateState: payload.updateState }))
        .addCase(clearUpdateState, (state) =>
            updateState(state, { updateSuccess: false, updateState: null, error: null })
        )
        .addCase(getBuildings.fulfilled, (state, { payload }) => updateState(state, { buildings: payload.buildings }))
        .addCase(getUpdatesBySource.fulfilled, (state, { payload }) =>
            updateState(state, { updatesAvailable: payload.updatesAvailable })
        )
        .addCase(getCollectMetricsInfo.fulfilled, (state, { payload }) =>
            updateState(state, { collectMetrics: payload.collectMetrics, cycle: payload.cycle })
        )
        .addMatcher(
            (action) => action.type.startsWith('buildings') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('buildings') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('buildings') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
