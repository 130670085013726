import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../../components/UI/Copyright/Copyright';
import { Button, Grid, Link } from '@mui/material';

export default function AfterSignUp() {
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sikeres Regisztráció!
                </Typography>
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h6">
                        Az email címed megerősítéséhez kiküldtünk egy emailt
                    </Typography>
                </Box>
                <Button variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Újraküldés
                </Button>

                <Grid container justifyContent="center">
                    <Grid item>
                        <Link href="signin" variant="body2">
                            Bejelentkezés
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
