import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    FormHelperText,
    Grid,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { validator } from '../../utils/validators';
import { forgotPassword } from '../../store/ducks/user';
import Copyright from '../../components/UI/Copyright/Copyright';
import { LockReset } from '@mui/icons-material';

const initialFormState = {
    email: {
        value: '',
        autoComplete: 'email',
        name: 'email',
        required: true,
        fullWidth: true,
        id: 'email',
        label: 'Email cím',
        error: false,
        helperText: 'Helytelen email cím',
    },
};

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState(initialFormState);
    const [sent, setSent] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!sent) {
            setSent(true);
            dispatch(forgotPassword(data.get('email')));
        } else {
            setSent(false);
            setFormState(initialFormState);
        }
    };

    const handleChange = (value, key) => {
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState),
                value,
            },
        }));
    };

    const anyError = () =>
        Object.keys(formState).some((key) => formState[key].value === '') ||
        Object.keys(formState).some((key) => formState[key].error);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockReset />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Elfelejtett Jelszó
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    {!sent && (
                        <>
                            <TextField
                                {...formState.email}
                                helperText={formState.email.error && formState.email.helperText}
                                onBlur={(event) => handleChange(event.target.value, formState.email.name)}
                                onChange={(event) => handleChange(event.target.value, formState.email.name)}
                            />
                            <FormHelperText>
                                A megadott email címre küldünk egy emailt, benne egy linkkel amire kattintva meg tudod
                                változtatni a jelszavadat
                            </FormHelperText>
                        </>
                    )}
                    {sent && <FormHelperText>Email elküldve</FormHelperText>}
                    <Button disabled={anyError()} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        {sent ? 'Újra' : 'Küldés'}
                    </Button>
                    <Grid container justifyContent="flex-start">
                        <Grid item>
                            <Link href="signin" variant="body2">
                                Belépés
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            <GoogleReCaptcha />
        </Container>
    );
}
