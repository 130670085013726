import compareVersions from 'compare-versions';
import { UPDATE_TYPES } from './constants';

export const updateState = (state, newValues) => ({ ...state, ...newValues });

export const emailValidator = (value) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    ) && value.length > 1;

export const passwordValidator = (value) => value.length > 5;

export const isAdmin = (user) => ['ADMIN', 'SUPER_ADMIN'].includes(user.role);

export const isWebAdmin = (user) => ['ADMIN', 'SUPER_ADMIN', 'WEB_ADMIN'].includes(user.role);

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const isNewerOrLatestVersion = (updateInfo, updates) => {
    const matchingUpdate = updates.find((update) => {
        return (
            update.source === updateInfo.source &&
            update.updateType === updateInfo.updateType &&
            update.moduleType === updateInfo.moduleType &&
            (updateInfo.updateType === UPDATE_TYPES.UNIQUE
                ? moduleIdsEquals(updateInfo.moduleIds.join(','), update.moduleIds.join(','))
                : true)
        );
    });
    if (matchingUpdate?.version && updateInfo.version) {
        return compareVersions(updateInfo.version, matchingUpdate.version) > -1;
    } else {
        return true;
    }
};

export const moduleIdsEquals = (a, b) =>
    a.length === b.length && a.sort().every((val, index) => val === b.sort()[index]);

export const mapAnnouncementFromServer = (announcement) => ({
    id: announcement._id,
    announcementType: announcement.announcementType,
    titleHu: announcement.title.hu,
    titleEn: announcement.title.en,
    titleDe: announcement.title.de,
    descriptionHu: announcement.description.hu,
    descriptionEn: announcement.description.en,
    descriptionDe: announcement.description.de,
    startDate: announcement.startDate,
    expireAt: announcement.expireAt,
    link: announcement.link,
});

export const mapAnnouncementToServer = (announcement) => ({
    id: announcement?.id,
    announcementType: announcement.announcementType,
    title: { hu: announcement.titleHu, en: announcement.titleEn, de: announcement.titleDe },
    description: { hu: announcement.descriptionHu, en: announcement.descriptionEn, de: announcement.descriptionDe },
    startDate: announcement.startDate,
    expireAt: announcement.expireAt,
    link: announcement.link,
});
