import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { Box, Fab, InputAdornment, Paper, Stack, TextField } from '@mui/material';
import NectiveHubsTable from '../../components/Tables/NectiveHubsTable/NectiveHubsTable';
import { QrReader } from 'react-qr-reader';
import { setToast } from '../../store/ducks/toast';
import { deleteNectiveHub, registerNectiveHub } from '../../store/ducks/nectiveHub';
import { validator } from '../../utils/validators';
import { NECTIVE_HUB } from '../../utils/constants';
import { DeleteOutline } from '@mui/icons-material';

export default function NectiveHubs() {
    const [reader, setReader] = useState(false);
    const [source, setSource] = useState('');
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();

    const addNectiveHubHandler = () => {
        dispatch(registerNectiveHub(source));
        setSource('');
        setReader(false);
        setChanged(false);
    };

    const isSourceValid = !validator('source', source, {}, NECTIVE_HUB);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <NectiveHubsTable
                                icons={{ Delete: () => <DeleteOutline color="secondary" /> }}
                                editable={{
                                    onRowDelete: (rowData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                dispatch(deleteNectiveHub(rowData.id));
                                                resolve();
                                            }, 0);
                                        }),
                                    onRowAdd: (newData) =>
                                        new Promise(async (resolve, reject) => {
                                            if (validator('source', newData.source, {}, NECTIVE_HUB)) {
                                                dispatch(
                                                    setToast({
                                                        severity: 'error',
                                                        open: true,
                                                        message: 'Nective azonosító nem megfelelő formátum',
                                                    })
                                                );
                                                reject();
                                            }
                                            addNectiveHubHandler(newData.source);
                                            resolve();
                                        }),
                                }}
                            />
                            <Grid container spacing={2} p={1}>
                                <Grid item xs={12} md={8} lg={4}>
                                    {reader && (
                                        <QrReader
                                            onResult={(result, error) => {
                                                if (!!result) {
                                                    setChanged(true);
                                                    setSource(result?.text);
                                                    setReader(false);
                                                }
                                                if (!!error) {
                                                    dispatch(
                                                        setToast({
                                                            severity: 'error',
                                                            open: true,
                                                            message: 'Valami gond van a kamerával',
                                                        })
                                                    );
                                                }
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <TextField
                                    label="Nective Hub azonosító"
                                    helperText={changed && !isSourceValid && 'Hibás Nective Hub azonosító formátum'}
                                    error={changed && !isSourceValid}
                                    value={source}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setReader((reader) => !reader)}
                                                    className="material-icons"
                                                >
                                                    qr_code_scanner
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        setChanged(true);
                                        setSource(event.target.value);
                                    }}
                                />

                                <Fab
                                    variant="extended"
                                    disabled={!changed || !isSourceValid}
                                    color="primary"
                                    aria-label="add"
                                    onClick={addNectiveHubHandler}
                                >
                                    Hozzáad
                                </Fab>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
