import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Table from '../../UI/Table/Table';

function PriceListTable({ ...tableProps }) {
    const priceList = useSelector((state) => state.priceList.priceList);
    const [priceListHun, setPriceListHun] = useState([]);

    useEffect(() => {
        const priceListHun = Object.keys(priceList)
            .filter((key) => !key.includes('_'))
            .map((key) => ({ name: priceList[key].name, price: priceList[key].price, key }));
        setPriceListHun(priceListHun);
    }, [priceList]);

    const formatter = new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0,
    });

    const columns = [
        {
            title: 'Eszköz neve',
            field: 'name',
            key: 'key',
        },
        { title: 'Ár', field: 'price', render: (rowData) => formatter.format(rowData.price) },
    ];

    return <Table title={<h2>Árlista</h2>} columns={columns} data={priceListHun} {...tableProps} />;
}
export default PriceListTable;
