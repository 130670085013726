import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './ducks/user';
import announcementReducer from './ducks/announcements';
import updateReducer from './ducks/updates';
import buildingsReducer from './ducks/buildings';
import wsReducer from './ducks/ws';
import priceListReducer from './ducks/priceList';
import statisticsReducer from './ducks/statistics';
import nectiveHubReducer from './ducks/nectiveHub';
import toastReducer from './ducks/toast';
import usersReducer from './ducks/users';
import contactReducer from './ducks/contacts';
import scriptReducer from './ducks/script';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    user: userReducer,
    users: usersReducer,
    contacts: contactReducer,
    announcements: announcementReducer,
    updates: updateReducer,
    buildings: buildingsReducer,
    priceList: priceListReducer,
    statistics: statisticsReducer,
    nectiveHub: nectiveHubReducer,
    ws: wsReducer,
    toast: toastReducer,
    script: scriptReducer,
});
const middleware =
    process.env.NODE_ENV !== 'production' ? [require('redux-immutable-state-invariant').default(), thunk] : [thunk];

const configureStore = () => createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default configureStore;
