import React from 'react';
import { MODULE_TYPES, UPDATE_TYPES } from '../../../utils/constants';

import Table from '../../UI/Table/Table';

function UpdatesTable({ updates, admin, ...tableProps }) {
    const columns = admin
        ? [
              { title: 'Azonosító', field: 'source' },
              { title: 'Típus', field: 'moduleType' },
              { title: 'Verzió', field: 'version' },
              {
                  title: 'Szoftver',
                  field: 'updateType',
              },
              { title: 'Modulok', field: 'moduleIds' },
              { title: 'Leírás', field: 'description' },
          ]
        : [
              { title: 'Típus', field: 'moduleType' },
              { title: 'Verzió', field: 'version' },
              {
                  title: 'Szoftver',
                  field: 'updateType',
              },
              { title: 'Modulok', field: 'moduleIds' },
              { title: 'Leírás', field: 'description' },
          ];

    return (
        <Table
            title={<h2>Frissítések</h2>}
            columns={columns}
            data={updates.map((update) => ({
                ...update,
                updateType: UPDATE_TYPES[update.updateType],
                moduleType: MODULE_TYPES[update.moduleType],
                source: update.source === 'ALL' ? 'Összes' : update.source,
                moduleIds:
                    update.moduleIds.length === 0 ? (update.moduleType !== 'LAN' ? 'Összes' : '-') : update.moduleIds,
                description: update.description || '-',
            }))}
            {...tableProps}
        />
    );
}
export default UpdatesTable;
