import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { differenceInDays } from 'date-fns';

import { isAdmin } from '../../utils/utilityFunctions';
import { Box, Paper } from '@mui/material';
import BuildingsTable from '../../components/Tables/BuildingsTable/BuildingsTable';
import UpdatesTable from '../../components/Tables/UpdatesTable/UpdatesTable';
import NectiveHubsTable from '../../components/Tables/NectiveHubsTable/NectiveHubsTable';
import PriceListTable from '../../components/Tables/PriceListTable/PriceListTable';
import Statistics from '../../components/Statistics/Statistics';
import UsersTable from '../../components/Tables/UsersTable/UsersTable';

export default function Dashboard() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const users = useSelector((state) => state.users.users);
    const { buildings, loading } = useSelector((state) => state.buildings);
    const updates = useSelector((state) => state.updates.updates);
    const admin = isAdmin(user);

    useEffect(() => {
        if (localStorage.path && !loading) {
            navigate(`${localStorage.path}`);
            localStorage.removeItem('path');
        }
    }, [navigate, loading]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            {!admin && (
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={12}>
                            <Paper
                                sx={{
                                    p: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <BuildingsTable title={<h2>Épületek</h2>} buildings={user.buildings} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            )}
            {admin && (
                <>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <UsersTable title={<h2>Felhasználók</h2>} users={users} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <BuildingsTable title={<h2>Épületek (összes)</h2>} buildings={buildings} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <BuildingsTable
                                        title={<h2>Épületek amik soha vagy több mint 1 napja nem küldtek státuszt</h2>}
                                        buildings={buildings.filter(
                                            (building) =>
                                                building.lastStatusSent === '-' ||
                                                differenceInDays(new Date(), new Date(building.lastStatusSent)) > 1
                                        )}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <UsersTable
                                        title={<h2>Felhasználók akik soha vagy több mint 1 hete nem voltak online</h2>}
                                        users={users.filter(
                                            (user) =>
                                                user.lastLogin === '-' ||
                                                differenceInDays(new Date(), new Date(user.lastLogin)) > 7
                                        )}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <UpdatesTable updates={updates} admin />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <NectiveHubsTable />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <PriceListTable />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} lg={12}>
                                <Paper
                                    sx={{
                                        p: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Statistics />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </Box>
    );
}
