import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Fab, Grid, Paper, Stack, Toolbar } from '@mui/material';
import BuildingsTable from '../../components/Tables/BuildingsTable/BuildingsTable';
import { Add, DeleteOutline, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { deleteBuilding } from '../../store/ducks/user';
import { isAdmin } from '../../utils/utilityFunctions';

export default function Buildings({ admin }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const buildings = useSelector((state) => state.buildings.buildings);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <BuildingsTable
                                icons={{
                                    Delete: (data) => !data.disabled && <DeleteOutline color="secondary" />,
                                }}
                                title={<h2>Épületek</h2>}
                                buildings={admin && isAdmin(user) ? buildings : user.buildings}
                                editable={{
                                    isDeletable: (rowData) =>
                                        (admin && isAdmin(user)) || rowData.mainUser === user.email,
                                    onRowDelete: (rowData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                dispatch(deleteBuilding(rowData.source));
                                                resolve();
                                            }, 0);
                                        }),
                                }}
                                actions={[
                                    (rowData) => ({
                                        icon: (data) => !data.disabled && <Edit color="primary" />,
                                        tooltip: 'Szerkesztés',
                                        onClick: () => navigate(`/edit-building?source=${rowData.source}`),
                                        disabled: admin ? !isAdmin(user) : rowData.mainUser !== user.email,
                                    }),
                                ]}
                            />
                            <Stack direction="row" marginTop={2} spacing={2} alignItems="center">
                                <Fab
                                    variant="extended"
                                    color="primary"
                                    aria-label="add"
                                    onClick={() => navigate('/edit-building?source=new')}
                                >
                                    <Add sx={{ mr: 1 }} />
                                    Új Épület Hozzáadása
                                </Fab>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
