import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getUsers = createAsyncThunk('users/getUsers', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/users');
        return { users: response.data.filter((u) => u.email !== 'building@building.bu') };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a felhasználók lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const removeUser = createAsyncThunk('users/removeUser', async ({ email, enabled }, thunkAPI) => {
    try {
        await apiAxios.delete(`/admin/users/${email}?enabled=${enabled}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen törölted a felhasználót a rendszerből',
            })
        );
        thunkAPI.dispatch(getUsers());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Felhasználó törlése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const enableUser = createAsyncThunk('users/enableUser', async (email, thunkAPI) => {
    try {
        await apiAxios.post(`/admin/enable-user/${email}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen engedélyezted a felhasználót a rendszerbe',
            })
        );
        thunkAPI.dispatch(getUsers());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Felhasználó engedélyezése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    error: null,
    loading: false,
    users: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getUsers.fulfilled, (state, { payload }) => updateState(state, { users: payload.users }))
        .addMatcher(
            (action) => action.type.startsWith('users') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('users') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('users') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
