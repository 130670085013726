const test = {
    apiUrl: 'https://test.nective.hu/server/api',
    authUrl: 'https://test.nective.hu/auth',
    hisUrl: '-',
    broker: 'https://test.nective.hu/websocket',
    reCaptchaId: '6LcHr_ApAAAAABq-2jTNv6yR9kSFnqSnUw-bGQiu',
};

const prod = {
    apiUrl: '/server/api',
    authUrl: '/auth',
    hisUrl: '-',
    broker: '/websocket',
    reCaptchaId: '6LcHr_ApAAAAABq-2jTNv6yR9kSFnqSnUw-bGQiu',
};

const config = process.env.REACT_APP_STAGE === 'test' ? test : prod;

export default config;
