import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { Box, Paper } from '@mui/material';
import PriceListTable from '../../components/Tables/PriceListTable/PriceListTable';
import { updatePriceList } from '../../store/ducks/priceList';

export default function PriceList() {
    const dispatch = useDispatch();
    const priceList = useSelector((state) => state.priceList.priceList);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <PriceListTable
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            if (newData.price < 0) {
                                                reject();
                                            }
                                            setTimeout(() => {
                                                dispatch(
                                                    updatePriceList({
                                                        ...priceList,
                                                        [oldData.key]: {
                                                            price: +newData.price,
                                                            name: newData.name,
                                                        },
                                                    })
                                                );
                                                resolve();
                                            }, 0);
                                        }),
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
