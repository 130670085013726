import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Paper, Fab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Announcement from './Announcement/Announcement';
import { mapAnnouncementFromServer } from '../../utils/utilityFunctions';

// const announcements = [
//     {
//         id: 'kamuid',
//         announcementType: 'INFO',
//         title: { hu: 'hu cim', en: 'en cim', de: 'de cim' },
//         description: { hu: 'hu leiras', en: 'en leiras', de: 'de leiras' },
//         startDate: new Date(Date.now() + 0),
//         expireAt: new Date(Date.now() + 7 * 24 * 3600 * 1000),
//         link: 'http://nective.hu/blalbla',
//     },
//     {
//         id: 'kamuid2',
//         announcementType: 'INFO',
//         title: { hu: 'hu cim2', en: 'en cim2', de: 'de cim2' },
//         description: { hu: 'hu leiras2', en: 'en leiras2', de: 'de leiras2' },
//         startDate: new Date(Date.now() + 0),
//         expireAt: new Date(Date.now() + 7 * 24 * 3600 * 1000),
//         link: 'http://nective.hu/blalbla2',
//     },
// ];

export default function Announcements() {
    const dispatch = useDispatch();
    const [showCreateAnnouncement, setShowCreateAnnouncement] = useState(false);
    const announcements = useSelector((state) => state.announcements.announcements);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {showCreateAnnouncement && (
                    <Announcement
                        key={'newAnnouncement'}
                        announcement={{}}
                        dispatch={dispatch}
                        setShowCreateAnnouncement={setShowCreateAnnouncement}
                    />
                )}
                {!showCreateAnnouncement && (
                    <>
                        {announcements.length ? (
                            announcements.map((announcement) => (
                                <Announcement
                                    key={announcement._id}
                                    announcement={mapAnnouncementFromServer(announcement)}
                                    dispatch={dispatch}
                                    setShowCreateAnnouncement={setShowCreateAnnouncement}
                                />
                            ))
                        ) : (
                            <Grid container spacing={3} pb={5}>
                                <Grid item xs={12} md={8} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 4,
                                        }}
                                    >
                                        Nincs aktuális közlemény
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container>
                            <Fab
                                variant="extended"
                                color="primary"
                                aria-label="save"
                                onClick={() => setShowCreateAnnouncement(true)}
                            >
                                Új közlemény
                            </Fab>
                        </Grid>
                    </>
                )}
            </Container>
        </Box>
    );
}
