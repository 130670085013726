import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { getBuildings } from '../../store/ducks/buildings';
import { getStatistics } from '../../store/ducks/statistics';
import { getNectiveHubs } from '../../store/ducks/nectiveHub';
import { getPriceList } from '../../store/ducks/priceList';
import { getUpdates } from '../../store/ducks/updates';
import { getUser } from '../../store/ducks/user';
import { getAvailableAnnouncements } from '../../store/ducks/announcements';
import { getUsers } from '../../store/ducks/users';
import { getContacts } from '../../store/ducks/contacts';

export default function Loading() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token && new Date(token.expireDate).getTime() > new Date().getTime()) {
            asyncLoadData();
        } else {
            navigate('/signin');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.user.email) {
            navigate('/');
        }
    }, [user, navigate]);

    const asyncLoadData = async () => {
        dispatch(getUser());
        dispatch(getUsers());
        dispatch(getContacts());
        dispatch(getBuildings());
        dispatch(getStatistics());
        dispatch(getNectiveHubs());
        dispatch(getPriceList());
        dispatch(getUpdates());
        dispatch(getAvailableAnnouncements());
    };

    return <Outlet />;
}
