import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import apiAxios from '../../axios/apiAxios';

import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getNectiveHubs = createAsyncThunk('nectiveHub/getNectiveHubs', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/nectiveHub/');
        return { nectiveHubs: response.data };
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const registerNectiveHub = createAsyncThunk('nectiveHub/registerNectiveHub', async (source, thunkAPI) => {
    try {
        await apiAxios.post(`/admin/nectiveHub/${source}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeres Nective Hub azonosító regisztrálás',
            })
        );
        thunkAPI.dispatch(getNectiveHubs());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a Nective Hub azonosító regisztrációja',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteNectiveHub = createAsyncThunk('nectiveHub/deleteNectiveHub', async (id, thunkAPI) => {
    try {
        await apiAxios.delete(`/admin/nectiveHub/${id}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Nective hub azonosító törölve',
            })
        );
        thunkAPI.dispatch(getNectiveHubs());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a Nective Hub azonosító törlése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    nectiveHubs: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getNectiveHubs.fulfilled, (state, { payload }) =>
            updateState(state, { nectiveHubs: payload.nectiveHubs })
        )
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false, authenticated: false })
        );
});
