export const EMAIL = 'EMAIL';
export const PASSWORD = 'PASSWORD';
export const RE_PASSWORD = 'RE_PASSWORD';
export const NAME = 'NAME';
export const COUNTRY = 'COUNTRY';
export const CITY = 'CITY';
export const ZIP_CODE = 'ZIP_CODE';

export const WEB_ADMIN = 'WEB_ADMIN';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const SET_LIGHT_MODULE_SOFTWARE_TYPE = 'SET_LIGHT_MODULE_SOFTWARE_TYPE';

export const GENERAL = 'GENERAL';
export const UNIQUE = 'UNIQUE';
export const ALL = 'ALL';
export const NECTIVE_HUB = 'NECTIVE_HUB';
export const LAN = 'LAN';
export const LIGHT = 'LIGHT';
export const LIGHT2 = 'LIGHT2';
export const DISPLAY = 'DISPLAY';
export const BASIC = 'BASIC';
export const SMART_BRIDGE = 'SMART_BRIDGE';
export const SMARTBRIDGE_ENERGRADE_KOM = 'SMARTBRIDGE_ENERGRADE_KOM';

export const tableLocalization = {
    body: {
        emptyDataSourceMessage: 'Nincsenek megjeleníthető elemek',
        deleteTooltip: 'Törlés',
        addTooltip: 'Hozzáadás',
        filterRow: {
            filterTooltip: 'Szűrő',
        },
        editRow: {
            deleteText: 'Biztosan törölni akarod ezt az elemet?',
            cancelTooltip: 'Mégse',
            saveTooltip: 'Igen',
        },
    },
    header: {
        actions: 'Akciók',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} / {count}',
        labelRowsSelect: 'Sor',
        labelRowsPerPage: 'Sorok száma oldalanként: ',
        firstAriaLabel: 'Első oldal',
        firstTooltip: 'Első oldal',
        previousAriaLabel: 'Előző oldal',
        previousTooltip: 'Előző oldal',
        nextAriaLabel: 'Következő oldal',
        nextTooltip: 'Következő oldal',
        lastAriaLabel: 'Utolsó oldal',
        lastTooltip: 'Utolsó oldal',
    },
    toolbar: {
        addRemoveColumns: 'Hozzáadás vagy törlés',
        nRowsSelected: '{0} sor kijelölve',
        searchTooltip: 'Keresés',
        searchPlaceholder: 'Keresés',
    },
};

export const BUILDING_SOURCE_REGEXP = /^[0-9a-fA-F]+$/;
export const NECTIVE_HUB_SOURCE_REGEXP = /^[a-fA-F0-9]+$/;

export const GMT0 = 'GMT';
export const GMT1 = 'GMT+1:00';
export const GMT2 = 'GMT+2:00';
export const GMT3 = 'GMT+3:00';
export const GMT4 = 'GMT+4:00';
export const GMT5 = 'GMT+5:00';
export const GMT6 = 'GMT+6:00';
export const GMT7 = 'GMT+7:00';
export const GMT8 = 'GMT+8:00';
export const GMT9 = 'GMT+9:00';
export const GMT10 = 'GMT+10:00';
export const GMT11 = 'GMT+11:00';
export const GMT12 = 'GMT+12:00';
export const GMT13 = 'GMT+13:00';
export const GMTN1 = 'GMT-1:00';
export const GMTN2 = 'GMT+2:00';
export const GMTN3 = 'GMT+3:00';
export const GMTN4 = 'GMT+4:00';
export const GMTN5 = 'GMT+5:00';
export const GMTN6 = 'GMT+6:00';
export const GMTN7 = 'GMT+7:00';
export const GMTN8 = 'GMT+8:00';
export const GMTN9 = 'GMT+9:00';
export const GMTN10 = 'GMT+10:00';
export const GMTN11 = 'GMT+11:00';
export const TIME_ZONE_LIST = [
    {
        name: GMTN11,
        id: GMTN11,
        value: 660,
    },
    {
        name: GMTN10,
        id: GMTN10,
        value: 600,
    },
    {
        name: GMTN9,
        id: GMTN9,
        value: 540,
    },
    {
        name: GMTN8,
        id: GMTN8,
        value: 480,
    },
    {
        name: GMTN7,
        id: GMTN7,
        value: 420,
    },
    {
        name: GMTN6,
        id: GMTN6,
        value: 360,
    },
    {
        name: GMTN5,
        id: GMTN5,
        value: 300,
    },
    {
        name: GMTN4,
        id: GMTN4,
        value: 240,
    },
    {
        name: GMTN3,
        id: GMTN3,
        value: 180,
    },
    {
        name: GMTN2,
        id: GMTN2,
        value: 120,
    },
    {
        name: GMTN1,
        id: GMTN1,
        value: 60,
    },
    {
        name: GMT0,
        id: GMT0,
        value: 0,
    },
    {
        name: GMT1,
        id: GMT1,
        value: -60,
    },
    {
        name: GMT2,
        id: GMT2,
        value: -120,
    },
    {
        name: GMT3,
        id: GMT3,
        value: -180,
    },
    {
        name: GMT4,
        id: GMT4,
        value: -240,
    },
    {
        name: GMT5,
        id: GMT5,
        value: -300,
    },
    {
        name: GMT6,
        id: GMT6,
        value: -360,
    },
    {
        name: GMT7,
        id: GMT7,
        value: -420,
    },
    {
        name: GMT8,
        id: GMT8,
        value: -480,
    },
    {
        name: GMT9,
        id: GMT9,
        value: -540,
    },
    {
        name: GMT10,
        id: GMT10,
        value: -600,
    },
    {
        name: GMT11,
        id: GMT11,
        value: -660,
    },
    {
        name: GMT12,
        id: GMT12,
        value: -720,
    },
];

export const MODULE_TYPES = {
    LAN: 'Lan',
    NECTIVE_HUB: 'Nective Hub',
    LIGHT: 'Light',
    LIGHT2: 'Light2',
    DISPLAY: 'Display',
    BASIC: 'Basic',
    SMART_BRIDGE: 'Smart Bridge',
    SMARTBRIDGE_ENERGRADE_KOM: 'Smart Bridge Energrade Kom',
};

export const SUBSCRIPTION_TYPES = {
    FREE: 'Ingyenes',
    BMS: 'Bms',
    SILVER: 'Ezüst',
    GOLD: 'Arany',
    PLATINA: 'Platina',
};

export const UPDATE_TYPES = {
    GENERAL: 'Gyári',
    UNIQUE: 'Egyedi',
};

export const UPDATE_TYPES_REVERSE = {
    Gyári: 'GENERAL',
    Egyedi: 'UNIQUE',
};

export const MODULE_TYPES_REVERSE = {
    Lan: 'LAN',
    'Nective Hub': NECTIVE_HUB,
    Light: LIGHT,
    Light2: LIGHT2,
    Display: DISPLAY,
    Basic: BASIC,
    'Smart Bridge Energrade Kom': SMARTBRIDGE_ENERGRADE_KOM,
    'Smart Bridge': SMART_BRIDGE,
};

export const ANNOUNCEMENT_TYPES = {
    INFO: 'Információ',
    WARNING: 'Figyelmeztetés',
};
