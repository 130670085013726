import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setToast } from '../../../store/ducks/toast';

export default function Toast({ severity, open, message }) {
    const dispatch = useDispatch();
    return (
        <Snackbar
            open={open}
            autoHideDuration={4000}
            onClose={() => dispatch(setToast({ open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Alert
                onClose={() => dispatch(setToast({ open: false }))}
                variant="filled"
                severity={severity}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}
