import React, { useContext, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import huLocale from 'date-fns/locale/hu';

import SignIn from './containers/SignIn/SignIn';
import SignUp from './containers/SignUp/SignUp';
import AfterSignUp from './containers/AfterSignUp/AfterSignUp';
import Dashboard from './containers/Dashboard/Dashboard';
import GuardedRoute from './components/GuardedRoute/GuardedRoute';
import Layout from './components/UI/Layout/Layout';
import Buildings from './containers/Buildings/Buildings';
import EditBuilding from './containers/EditBuilding/EditBuilding';
import BuildingInfo from './containers/BuildingInfo/BuildingInfo';
import Toast from './components/UI/Toast/Toast';

import Notifications from './containers/Notifications/Notifications';
import Loading from './components/Loading/Loading';
import { ColorModeContext } from './context/ColorModeContext';
import User from './containers/User/User';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import NectiveHubs from './containers/NectiveHubs/NectiveHubs';
import AdminRoute from './components/AdminRoute/AdminRoute';
import Updates from './containers/Updates/Updates';
import PriceList from './containers/PriceList/PriceList';
import StatisticsPage from './containers/Statistics/Statistics';
import Announcements from './containers/Announcements/Announcements';
import Users from './containers/Users/Users';
import Contacts from './containers/Contacts/Contacts';

function App() {
    const user = useSelector((state) => state.user);
    const state = useSelector((state) => state);
    const toast = useSelector((state) => state.toast);
    const { mode } = useContext(ColorModeContext);

    const theme = useMemo(
        () =>
            createTheme(
                {
                    palette: {
                        mode,
                        primary: { main: '#00A8EC' },
                        secondary: { main: '#ec00a8' },
                    },
                    components: {
                        MuiButton: {
                            styleOverrides: {
                                root: {
                                    color: 'white',
                                },
                            },
                        },
                        MuiAppBar: {
                            styleOverrides: {
                                root: {
                                    color: 'white',
                                },
                            },
                        },
                        MuiFab: {
                            styleOverrides: {
                                root: {
                                    color: 'white',
                                },
                            },
                        },
                    },
                },
                huLocale
            ),
        [mode]
    );

    return (
        <ThemeProvider theme={theme}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                open={Object.keys(state).some((key) => state[key].loading)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Toast {...toast} />
            <Routes>
                <Route path="loading" element={<Loading />} />
                <Route path="signin" element={<SignIn />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="after-signup" element={<AfterSignUp />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route element={<GuardedRoute user={user} />}>
                    <Route
                        index
                        element={
                            <Layout>
                                <Dashboard />
                            </Layout>
                        }
                    />
                    <Route
                        path="/buildings"
                        element={
                            <Layout>
                                <Buildings />
                            </Layout>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <Layout>
                                <Users />
                            </Layout>
                        }
                    />
                    <Route
                        path="/edit-building"
                        element={
                            <Layout>
                                <EditBuilding />
                            </Layout>
                        }
                    />
                    <Route
                        path="/building"
                        element={
                            <Layout>
                                <BuildingInfo />
                            </Layout>
                        }
                    />
                    <Route
                        path="/user"
                        element={
                            <Layout>
                                <User />
                            </Layout>
                        }
                    />
                    <Route
                        path="/notifications"
                        element={
                            <Layout>
                                <Notifications />
                            </Layout>
                        }
                    />
                    <Route
                        path="/change-password-user"
                        element={
                            <Layout>
                                <ChangePassword />
                            </Layout>
                        }
                    />
                    <Route element={<AdminRoute user={user} />}>
                        <Route
                            path="/admin-buildings"
                            element={
                                <Layout>
                                    <Buildings admin />
                                </Layout>
                            }
                        />
                        <Route
                            path="/nective-hubs"
                            element={
                                <Layout>
                                    <NectiveHubs />
                                </Layout>
                            }
                        />
                        <Route
                            path="/announcements"
                            element={
                                <Layout>
                                    <Announcements />
                                </Layout>
                            }
                        />
                        <Route
                            path="/updates"
                            element={
                                <Layout>
                                    <Updates />
                                </Layout>
                            }
                        />
                        <Route
                            path="/contacts"
                            element={
                                <Layout>
                                    <Contacts admin />
                                </Layout>
                            }
                        />
                        <Route
                            path="/pricelist"
                            element={
                                <Layout>
                                    <PriceList />
                                </Layout>
                            }
                        />
                        <Route
                            path="/statistics"
                            element={
                                <Layout>
                                    <StatisticsPage />
                                </Layout>
                            }
                        />
                    </Route>
                </Route>
                <Route path="*" element={<p>Nincs itt semmi: 404!</p>} />
            </Routes>
        </ThemeProvider>
    );
}
export default App;
