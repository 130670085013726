import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import apiAxios from '../../axios/apiAxios';
import axios from 'axios';

import { updateState } from '../../utils/utilityFunctions';
import { getBuildings } from './buildings';
import { setToast } from './toast';

export const logout = createAction('user/logout');

export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/user');
        return { user: response.data };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Felhasználó lekérése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const updateUser = createAsyncThunk('user/updateUser', async (user, thunkAPI) => {
    try {
        await apiAxios.put('/user', user);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen módosítottad a felasználói adatokat',
            })
        );
        thunkAPI.dispatch(getUser());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Felhasználói adatok frissítése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const forgotPassword = createAsyncThunk('user/forgotPassword', async (email, thunkAPI) => {
    try {
        await apiAxios.get(`/public/forgot-password-new/${email}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Az emailt kiküldtük',
            })
        );
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nincs felhasználó ezzel az email címmel',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const changePassword = createAsyncThunk(
    'user/changePassword',
    async ({ oldPassword, newPassword, email, token }, thunkAPI) => {
        try {
            await apiAxios.put(email && token ? `/public/change-password/${email}/${token}` : '/user/password', {
                oldPassword,
                newPassword,
            });
            thunkAPI.dispatch(
                setToast({
                    severity: 'success',
                    open: true,
                    message: 'Sikeresen módosítottad a jelszavadat',
                })
            );
        } catch (err) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message:
                        err.response.status === 406
                            ? 'Jelszó módosítása sikertelen, hibás jelenlegi jelszót adtál meg'
                            : 'Jelszó módosítása sikertelen',
                })
            );
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const deleteUser = createAsyncThunk('user/deleteUser', async (_, thunkAPI) => {
    try {
        await apiAxios.delete('/user');
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen törölted magad a rendszerből',
            })
        );
        thunkAPI.dispatch(logout());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Felhasználó törlése sikertelen',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const login = createAsyncThunk('user/login', async ({ email, password }, thunkAPI) => {
    try {
        const response = await axios.post('/login', { email, password });
        const { token, expiresIn } = response.data;
        const expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + expiresIn);
        localStorage.setItem('token', JSON.stringify({ value: token, expireDate }));
        setTimeout(() => {
            localStorage.removeItem('token');
            window.location.reload();
        }, expiresIn * 1000);
        thunkAPI.dispatch(getUser());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Bejelentkezés sikertelen, rossz felhasználó név vagy jelszó',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const signUp = createAsyncThunk('user/signup', async (signUpData, thunkAPI) => {
    try {
        await apiAxios.post('/public/sign-up', signUpData);
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Sikertelen regisztráció, kérlek próbáld meg később',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const addBuilding = createAsyncThunk('user/addBuilding', async (buildingData, thunkAPI) => {
    try {
        await apiAxios.post('/building', buildingData);
        thunkAPI.dispatch(getUser());
        thunkAPI.dispatch(getBuildings());
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen hozzáadtad az új épületet',
            })
        );
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message:
                    err.response?.status === 406
                        ? 'Az épület már létezik ezzel a névvel'
                        : 'Nem sikerült hozzáadni az épületet',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const updateBuilding = createAsyncThunk('user/updateBuilding', async ({ source, buildingData }, thunkAPI) => {
    try {
        await apiAxios.put(`/building/${source}`, buildingData);
        thunkAPI.dispatch(getUser());
        thunkAPI.dispatch(getBuildings());
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen módosítottad az épület adatait',
            })
        );
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az épület módosítása',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const changeSubscription = createAsyncThunk(
    'user/changeSubscription',
    async ({ source, subscription }, thunkAPI) => {
        console.log(source, subscription);
        try {
            await apiAxios.post(`/admin/subscription/${source}`, { subscription });
            thunkAPI.dispatch(getUser());
            thunkAPI.dispatch(getBuildings());
            thunkAPI.dispatch(
                setToast({
                    severity: 'success',
                    open: true,
                    message: 'Sikeresen átállítottad az épület feliratkozását',
                })
            );
        } catch (err) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült átállítani a feliratkozást',
                })
            );
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const inviteUserToControlBuilding = createAsyncThunk(
    'user/inviteUserToControlBuilding',
    async ({ source, email }, thunkAPI) => {
        try {
            await apiAxios.post(`/user/invite/${source}/${email}`);
        } catch (err) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült a felhasználó(k) meghívása',
                })
            );
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const setBanList = createAsyncThunk('user/setBanList', async ({ source, emailList }, thunkAPI) => {
    try {
        await apiAxios.post(`/building/banlist/${source}`, emailList);
        thunkAPI.dispatch(getUser());
        thunkAPI.dispatch(getBuildings());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a felhasználó(k) tiltása',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteBuilding = createAsyncThunk('user/deleteBuilding', async (source, thunkAPI) => {
    try {
        await apiAxios.delete(`/building/${source}`);
        thunkAPI.dispatch(getUser());
        thunkAPI.dispatch(getBuildings());
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen törölted az épületet',
            })
        );
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az épület törlése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const enableNectiveHub = createAsyncThunk('user/enableNectiveHub', async ({ source, key }, thunkAPI) => {
    try {
        await apiAxios.post(`/building/nectivehub/enable/${source}/${key}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen engedélyezted a Nective Hub-ot',
            })
        );
        thunkAPI.dispatch(getUser());
    } catch (err) {
        if (err.response.status === 403) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült a Nective Hub engedélyezése, Helytelen kódot adtál meg',
                })
            );
        } else {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült a Nective Hub engedélyezése',
                })
            );
        }
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const readNotification = createAsyncThunk('notifications/readNotification', async (id, thunkAPI) => {
    try {
        await apiAxios.post(`/user/sent-notifications/${id}`);
        thunkAPI.dispatch(getUser());
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const readAllNotifications = createAsyncThunk('notifications/readAllNotifications', async (_, thunkAPI) => {
    try {
        await apiAxios.post(`/user/all-notifications`);
        thunkAPI.dispatch(getUser());
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteAllNotification = createAsyncThunk('notifications/deleteAllNotification', async (_, thunkAPI) => {
    try {
        await apiAxios.delete(`/user/all-notifications`);
        thunkAPI.dispatch(getUser());
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    authenticated: false,
    user: {
        id: null,
        email: '',
        role: '',
    },
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getUser.fulfilled, (state, { payload }) =>
            updateState(state, { user: payload.user, authenticated: true })
        )
        .addCase(logout, () => initialState)
        .addMatcher(
            (action) => action.type.startsWith('user') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('user') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('user') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
