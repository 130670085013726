import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getStatistics = createAsyncThunk('statistics/getStatistics', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/statistics');
        return { statistics: response.data };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült a statisztikák lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    statistics: {},
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getStatistics.fulfilled, (state, { payload }) =>
            updateState(state, { statistics: payload.statistics })
        )
        .addMatcher(
            (action) => action.type.startsWith('statistics') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('statistics') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('statistics') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false, authenticated: false })
        );
});
