import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getUpdates = createAsyncThunk('updates/getUpdates', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/admin/update');
        return { updates: response.data };
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const saveUpdate = createAsyncThunk('updates/saveUpdate', async (updateInfo, thunkAPI) => {
    try {
        await apiAxios.post('/admin/update', updateInfo);
        thunkAPI.dispatch(getUpdates());
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen hozzáadtad a frissítést',
            })
        );
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült hozzáadni a frissítést',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const deleteUpdate = createAsyncThunk('updates/deleteUpdate', async (id, thunkAPI) => {
    try {
        await apiAxios.delete(`/admin/update/${id}`);
        thunkAPI.dispatch(getUpdates());
        setToast({
            severity: 'success',
            open: true,
            message: 'Sikeresen törölted a frissítést',
        });
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült törölni a frissítést',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const uploadFile = createAsyncThunk('updates/uploadFile', async ({ formData, version }, thunkAPI) => {
    try {
        await apiAxios.post(`/admin/update/nective-hub/${version}`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    status: null,
    updates: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getUpdates.fulfilled, (state, { payload }) => updateState(state, { updates: payload.updates }))
        .addMatcher(
            (action) => action.type.startsWith('updates') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('updates') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('updates') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
