import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import {
    Box,
    Fab,
    InputLabel,
    Paper,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    Typography,
} from '@mui/material';
import {
    ALL,
    BASIC,
    DISPLAY,
    GENERAL,
    LAN,
    LIGHT,
    LIGHT2,
    MODULE_TYPES,
    NECTIVE_HUB,
    SMARTBRIDGE_ENERGRADE_KOM,
    SMART_BRIDGE,
    UNIQUE,
    UPDATE_TYPES,
} from '../../utils/constants';
import { DeleteOutline } from '@mui/icons-material';
import UpdatesTable from '../../components/Tables/UpdatesTable/UpdatesTable';
import { deleteUpdate, saveUpdate, uploadFile } from '../../store/ducks/updates';
import { isNewerOrLatestVersion } from '../../utils/utilityFunctions';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';

export default function Updates() {
    const updates = useSelector((state) => state.updates.updates);
    const [showForm, setShowForm] = useState(false);
    const [firmwareFile, setFirmwareFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const [fileName, setFileName] = useState('');
    const [updateType, setUpdateType] = useState(GENERAL);
    const [moduleType, setModuleType] = useState(LAN);
    const [source, setSource] = useState('Összes');
    const [hoverred, setHoverred] = useState(false);
    const [version, setVersion] = useState('');
    const [moduleIds, setModuleIds] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [localUpdateInfo, setLocalUpdateInfo] = useState(null);

    const dispatch = useDispatch();
    const fileRef = useRef();

    const saveHandler = () => {
        let updateInfo = {};
        if (moduleType === NECTIVE_HUB) {
            const formData = new FormData();
            formData.append('app', selectedFile, selectedFile.name);
            updateInfo = {
                description,
                updateType,
                moduleType,
                source: source === 'Összes' ? ALL : source,
                moduleIds: moduleIds?.length ? moduleIds.split(',').map((moduleId) => +moduleId) : [],
                version,
            };
            dispatch(uploadFile({ formData, version }));
        } else {
            const { bootFile, ...bootFileInfo } = firmwareFile;
            updateInfo = {
                description,
                updateType,
                moduleType,
                source: source === 'Összes' ? ALL : source,
                moduleIds: moduleIds?.length ? moduleIds.split(',').map((moduleId) => +moduleId) : [],
                bootFileHex: bootFile,
                bootFileInfo: { ...bootFileInfo },
                version,
            };
        }
        const isVersionOk = isNewerOrLatestVersion(updateInfo, updates);
        if (isVersionOk) {
            dispatch(saveUpdate(updateInfo));
            clearState();
            setShowForm(false);
            fileRef.current.value = null;
        } else {
            setLocalUpdateInfo(updateInfo);
            setDialogOpen(true);
        }
    };

    const fileUploadHandler = (event) => {
        setFileName(event.target.files[0].name);
        if (moduleType === NECTIVE_HUB) {
            setSelectedFile(event.target.files[0]);
        } else {
            event.preventDefault();
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const bootFileJson = JSON.parse(text);
                setFirmwareFile(bootFileJson);
            };
            if (event.target.files[0]) {
                reader.readAsText(event.target.files[0]);
            }
        }
    };

    const clearState = () => {
        setFileName('');
        setFirmwareFile(null);
        setSelectedFile(null);
        setDescription('');
        setModuleType(LAN);
        setUpdateType(GENERAL);
        setSource('Összes');
        setModuleIds('');
        setVersion('');
    };

    return (
        <>
            <AlertDialog
                title="Mentés megerősítése"
                description="A frissítés verziója amit fel akarsz tölteni, alacsonyabb a jelenlegi legfrissebb verziónál, biztosan cseréled a frissítést?"
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAgree={() => {
                    dispatch(saveUpdate(localUpdateInfo));
                    clearState();
                    setShowForm(false);
                    fileRef.current.value = null;
                }}
            />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Toolbar />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={12}>
                            <Paper
                                sx={{
                                    p: 4,
                                }}
                            >
                                <UpdatesTable
                                    updates={updates}
                                    admin
                                    icons={{ Delete: () => <DeleteOutline color="secondary" /> }}
                                    editable={{
                                        onRowDelete: (rowData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    dispatch(deleteUpdate(rowData.id));
                                                    resolve();
                                                }, 0);
                                            }),
                                    }}
                                />
                                {showForm && (
                                    <Box component="form" noValidate sx={{ mt: 1, marginTop: 4 }}>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="version"
                                                    label="Verzió"
                                                    value={version}
                                                    type="text"
                                                    onChange={(event) => setVersion(event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    id="description"
                                                    label="Leírás"
                                                    value={description}
                                                    onChange={(event) => setDescription(event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="source"
                                                    label="Épületazonosító"
                                                    value={source}
                                                    type="text"
                                                    onChange={(event) => setSource(event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    id="Modul id lista"
                                                    label="Modul azonosító lista"
                                                    value={moduleIds}
                                                    type="text"
                                                    onChange={(event) => setModuleIds(event.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <FormControl required fullWidth>
                                                    <InputLabel id="updateType-label">Frissítés Típus</InputLabel>
                                                    <Select
                                                        required
                                                        id="updateType"
                                                        value={updateType}
                                                        onChange={(event) => setUpdateType(event.target.value)}
                                                        labelId="updateType-label"
                                                        label="Frissítés Típus"
                                                    >
                                                        <MenuItem value={GENERAL}>{UPDATE_TYPES.GENERAL}</MenuItem>
                                                        <MenuItem value={UNIQUE}>{UPDATE_TYPES.UNIQUE}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <FormControl required fullWidth>
                                                    <InputLabel id="updateType-label">Szoftver Típus</InputLabel>
                                                    <Select
                                                        id="updateType"
                                                        value={moduleType}
                                                        onChange={(event) => setModuleType(event.target.value)}
                                                        labelId="updateType-label"
                                                        label="Szoftver Típus"
                                                    >
                                                        <MenuItem value={LAN}>{MODULE_TYPES.LAN}</MenuItem>
                                                        <MenuItem value={LIGHT}>{MODULE_TYPES.LIGHT}</MenuItem>
                                                        <MenuItem value={LIGHT2}>{MODULE_TYPES.LIGHT2}</MenuItem>
                                                        <MenuItem value={DISPLAY}>{MODULE_TYPES.DISPLAY}</MenuItem>
                                                        <MenuItem value={BASIC}>{MODULE_TYPES.BASIC}</MenuItem>
                                                        <MenuItem value={SMART_BRIDGE}>
                                                            {MODULE_TYPES.SMART_BRIDGE}
                                                        </MenuItem>
                                                        <MenuItem value={SMARTBRIDGE_ENERGRADE_KOM}>
                                                            {MODULE_TYPES.SMARTBRIDGE_ENERGRADE_KOM}
                                                        </MenuItem>
                                                        <MenuItem value={'NECTIVE_HUB'}>
                                                            {MODULE_TYPES.NECTIVE_HUB}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} p={1}>
                                            <Grid item xs={12} md={8} lg={4}>
                                                <FormControl fullWidth sx={{ marginBottom: 4 }}>
                                                    <input
                                                        style={{
                                                            opacity: 0,
                                                            width: 0.1,
                                                            height: 0.1,
                                                            position: 'absolute',
                                                        }}
                                                        id="file"
                                                        ref={fileRef}
                                                        type="file"
                                                        name="file"
                                                        onChange={fileUploadHandler}
                                                    />
                                                    <label
                                                        onMouseEnter={() => setHoverred(true)}
                                                        onMouseLeave={() => setHoverred(false)}
                                                        style={{
                                                            position: 'relative',
                                                            width: 200,
                                                            height: 50,
                                                            borderRadius: 25,
                                                            background: hoverred ? 'rgb(0, 117, 165)' : '#00A8EC',
                                                            boxShadow: '0 4px 7px rgba(0, 0, 0, 0.4)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                            transition: 'transform .2s ease-out',
                                                        }}
                                                        htmlFor="file"
                                                    >
                                                        Fájl hozzáadás
                                                        <Typography
                                                            sx={{
                                                                position: 'absolute',
                                                                bottom: -35,
                                                                left: 10,
                                                                fontSize: '0.85rem',
                                                                color: '#555',
                                                            }}
                                                        >
                                                            {fileName || 'Nincs fájl kiválasztva'}
                                                        </Typography>
                                                    </label>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                <Stack direction="row" marginTop={2} spacing={2} alignItems="center">
                                    <Fab
                                        variant="extended"
                                        color={showForm ? 'secondary' : 'primary'}
                                        aria-label="add"
                                        onClick={() => {
                                            if (showForm) {
                                                clearState();
                                            }
                                            setShowForm((prevState) => !prevState);
                                        }}
                                    >
                                        {showForm ? 'Mégse' : 'Hozzáad'}
                                    </Fab>
                                    {showForm && (
                                        <Fab
                                            disabled={!version || !fileName || !source || !updateType || !moduleType}
                                            variant="extended"
                                            color="primary"
                                            aria-label="save"
                                            onClick={saveHandler}
                                        >
                                            Mentés
                                        </Fab>
                                    )}
                                </Stack>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
