import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SixDigitInput from '../SixDigitInput/SixDigitInput';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export default function DigitInputDialog({ open, onClose, finalValue }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="digit-dialog-title"
            aria-describedby="digit-dialog-description"
        >
            <Tooltip title="A készülék üzembehelyezésekor a készülék képernyőjén megjelenő 6 jegyű kód">
                <InfoOutlined sx={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} color="primary" />
            </Tooltip>
            <DialogTitle id="digit-dialog-title">Nective Hub 6 jegyű azonosító</DialogTitle>
            <DialogContent sx={{ position: 'relative' }}>
                <SixDigitInput finalValue={finalValue} />
            </DialogContent>
        </Dialog>
    );
}
