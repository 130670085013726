import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { DatePicker } from '@mui/lab';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';

import Copyright from '../../components/UI/Copyright/Copyright';
import { validator } from '../../utils/validators';
import { useDispatch } from 'react-redux';
import { signUp } from '../../store/ducks/user';

const initialFormState = {
    name: {
        value: '',
        autoComplete: 'given-name',
        name: 'name',
        required: true,
        fullWidth: true,
        id: 'name',
        label: 'Név',
        autoFocus: true,
        error: false,
        helperText: 'A névnek legalább 2 karakter hosszúnak kell lennie',
    },
    email: {
        value: '',
        autoComplete: 'email',
        name: 'email',
        required: true,
        fullWidth: true,
        id: 'email',
        label: 'Email cím',
        error: false,
        helperText: 'Helytelen email cím',
    },
    password: {
        value: '',
        autoComplete: 'password',
        name: 'password',
        type: 'password',
        required: true,
        fullWidth: true,
        id: 'password',
        label: 'Jelszó',
        error: false,
        helperText:
            'A jelszónak minimum 6 karakterből kell állnia és tartalmaznia kell legalább egy számot vagy legalább egy nagybetűt',
    },
    repassword: {
        value: '',
        autoComplete: 'password',
        name: 'repassword',
        type: 'password',
        required: true,
        fullWidth: true,
        id: 'repassword',
        label: 'Jelszó mégegyszer',
        error: false,
        helperText: 'A két jelszónak egyeznie kell',
    },
};

export default function SignUp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formState, setFormState] = useState(initialFormState);
    const [birthDate, setBirthDate] = useState();
    const [country, setCountry] = useState('hungary');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const signUpData = {
            name: formState.name.value,
            email: formState.email.value,
            password: formState.password.value,
            city: data.get('city'),
            zipcode: data.get('zipcode'),
            gender: data.get('gender'),
            birthDate,
            country,
        };
        dispatch(signUp(signUpData));
        navigate('/after-signup');
    };

    const handleChange = (value, key) => {
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState),
                value,
            },
        }));
    };

    const anyError = () =>
        Object.keys(formState).some((key) => formState[key].value === '') ||
        Object.keys(formState).some((key) => formState[key].error);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Regisztráció
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                {...formState.name}
                                helperText={formState.name.error && formState.name.helperText}
                                onBlur={(event) => handleChange(event.target.value, formState.name.name)}
                                onChange={(event) => handleChange(event.target.value, formState.name.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...formState.email}
                                helperText={formState.email.error && formState.email.helperText}
                                onBlur={(event) => handleChange(event.target.value, formState.email.name)}
                                onChange={(event) => handleChange(event.target.value, formState.email.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...formState.password}
                                helperText={formState.password.error && formState.password.helperText}
                                onBlur={(event) => handleChange(event.target.value, formState.password.name)}
                                onChange={(event) => handleChange(event.target.value, formState.password.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...formState.repassword}
                                helperText={formState.repassword.error && formState.repassword.helperText}
                                onBlur={(event) => handleChange(event.target.value, formState.repassword.name)}
                                onChange={(event) => handleChange(event.target.value, formState.repassword.name)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label="Születési Dátum"
                                value={birthDate}
                                onChange={(newValue) => {
                                    setBirthDate(newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="country-label">Ország</InputLabel>
                                <Select
                                    labelId="country-label"
                                    id="country-select"
                                    value={country}
                                    label="Ország"
                                    autoComplete="given-country"
                                    onChange={(event) => setCountry(event.target.value)}
                                >
                                    <MenuItem value="hungary">Magyarország</MenuItem>
                                    <MenuItem value="germany">Németország</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="city" fullWidth id="city" label="Város" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField type="number" name="zipcode" fullWidth id="zipcode" label="Irányítószám" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="gender-label">Neme</FormLabel>
                                <RadioGroup aria-labelledby="gender-label" defaultValue="female" name="gender">
                                    <FormControlLabel value="female" control={<Radio />} label="Nő" />
                                    <FormControlLabel value="male" control={<Radio />} label="Férfi" />
                                    <FormControlLabel value="other" control={<Radio />} label="Egyéb" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button disabled={anyError()} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Regisztráció
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="signin" variant="body2">
                                Van már regisztrációd? Belépés
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            <GoogleReCaptcha />
        </Container>
    );
}
