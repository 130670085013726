import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';

import { tableLocalization } from '../../../utils/constants';

function Table({ title, data, columns, tableOptions, ...tableProps }) {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const [tableState, setTableState] = useState({
        columns,
        data: dataCopy,
    });

    useEffect(() => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        setTableState((prevState) => ({ ...prevState, data: dataCopy }));
    }, [data]);

    return (
        <MaterialTable
            style={{ width: '100%', fontFamily: 'Roboto', fontSize: '0.9rem' }}
            title={title}
            columns={tableState.columns}
            data={tableState.data}
            localization={tableLocalization}
            options={{ actionsColumnIndex: -1, ...tableOptions }}
            {...tableProps}
        />
    );
}
export default Table;
