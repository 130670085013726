import { useMediaQuery } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';

export const ColorModeContext = createContext('light');

function ColorModeProvider(props) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');

    useEffect(() => {
        if (prefersDarkMode) {
            setMode('dark');
        }
    }, [prefersDarkMode]);

    const toggleColorMode = () => setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));

    return (
        <ColorModeContext.Provider
            value={{
                mode,
                toggleColorMode,
            }}
        >
            {props.children}
        </ColorModeContext.Provider>
    );
}
export default ColorModeProvider;
