import React from 'react';

import Table from '../../UI/Table/Table';

function ModuleTable({ modules, ...tableProps }) {
    const columns = [
        {
            title: 'Azonosító',
            field: 'source',
        },
        {
            title: 'Típus',
            field: 'type',
        },
        {
            title: 'Verzió',
            field: 'version',
        },
        {
            title: 'Szoftver',
            field: 'softwareType',
        },

        {
            title: 'Elérhető Frissítések',
            field: 'update',
            type: 'boolean',
        },
    ];

    return <Table title={<h2>Modulok</h2>} columns={columns} data={modules} {...tableProps} />;
}
export default ModuleTable;
