import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UNIQUE, GENERAL } from '../../../utils/constants';

export default function LightModuleSoftwareChangeDialog({ open, onClose, onChoose, title, description }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="light-software-dialog-title"
            aria-describedby="light-software-dialog-description"
        >
            <DialogTitle id="light-software-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="light-software-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ color: '#222222 !important' }} onClick={onClose}>
                    Mégse
                </Button>
                <Button
                    sx={{ color: '#00a8ec !important' }}
                    onClick={() => {
                        onChoose(GENERAL);
                        onClose();
                    }}
                    autoFocus
                >
                    Gyári
                </Button>
                <Button
                    sx={{ color: '#ec00a8 !important' }}
                    onClick={() => {
                        onChoose(UNIQUE);
                        onClose();
                    }}
                    autoFocus
                >
                    Egyedi
                </Button>
            </DialogActions>
        </Dialog>
    );
}
