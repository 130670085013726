import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import {
    Box,
    Container,
    Fab,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Toolbar,
} from '@mui/material';
import { Block, Check, DeleteOutline, Star } from '@mui/icons-material';

import { getBuildingBySource } from '../../store/filters';
import { TIME_ZONE_LIST } from '../../utils/constants';
import BuildingUsersTable from '../../components/Tables/BuildingUsersTable/BuildingUsersTable';
import apiAxios from '../../axios/apiAxios';
import { validator } from '../../utils/validators';
import {
    addBuilding,
    changeSubscription,
    inviteUserToControlBuilding,
    setBanList,
    updateBuilding,
} from '../../store/ducks/user';
import { setToast } from '../../store/ducks/toast';
import { isAdmin } from '../../utils/utilityFunctions';

export const initialState = (currentBuilding) => ({
    name: {
        value: currentBuilding?.name || '',
        name: 'name',
        required: true,
        fullWidth: true,
        id: 'name',
        label: 'Épület neve',
        error: false,
        helperText: 'A névnek legalább 2 karakter hosszúnak kell lennie',
    },
    source: {
        value: currentBuilding?.source || '',
        name: 'source',
        required: true,
        fullWidth: true,
        id: 'source',
        label: 'Épület azonosító',
        error: false,
        helperText: 'Helytelen azonosító',
    },
    address: {
        value: currentBuilding?.address || '',
        name: 'address',
        required: true,
        fullWidth: true,
        id: 'address',
        label: 'Épület címe',
        error: false,
        helperText: 'A címnek legalább 5 karakter hosszúnak kell lennie',
    },
});

export default function EditBuilding() {
    const [reader, setReader] = useState(false);
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { error, loading, user } = useSelector((state) => state.user);
    const queryParams = new URLSearchParams(location.search);
    const currentBuilding = useSelector((state) =>
        getBuildingBySource(state, queryParams.get('source'), isAdmin(user))
    );
    const [formState, setFormState] = useState(initialState(currentBuilding));
    const [zoneOffset, setZoneOffset] = useState(currentBuilding?.zoneOffset || new Date().getTimezoneOffset());
    const [mainModuleType, setMainModuleType] = useState(currentBuilding?.mainModuleType || 'LAN');
    const [subscriptionType, setSubscriptionType] = useState(currentBuilding?.subscriptionType || 'FREE');
    const [userList, setUserList] = useState(
        currentBuilding
            ? currentBuilding.userEmails.map((email) => ({
                  id: email,
                  email,
                  banned: currentBuilding.banList.includes(email),
                  mainUser: currentBuilding.mainUser === email,
              }))
            : [{ id: user.email, email: user.email, banned: false, mainUser: true }]
    );
    const edit = !!currentBuilding;

    useEffect(() => {
        if (currentBuilding) {
            setFormState(initialState(currentBuilding));
        }
    }, [currentBuilding]);

    const saveHandler = async () => {
        const buildingToSave = {
            source: mainModuleType === 'LAN' ? formState.source.value.toUpperCase() : formState.source.value,
            name: formState.name.value,
            mainUser: userList.find((u) => u.mainUser)?.email,
            userEmails: userList.map((u) => u.email.toLowerCase()),
            address: formState.address.value,
            mainModuleType,
            zoneOffset,
        };
        if (edit) {
            dispatch(updateBuilding({ source: currentBuilding.source, buildingData: buildingToSave }));
            dispatch(
                setBanList({
                    source: buildingToSave.source,
                    emailList: userList.filter((u) => u.banned).map((u) => u.email),
                })
            );
            setChanged(false);
        } else {
            if (buildingToSave.mainModuleType === 'LAN') {
                try {
                    const response = await apiAxios.get(`/building/operating-mode/${buildingToSave.source}`);
                    if (!response.data.settingMode) {
                        dispatch(
                            setToast({ severity: 'error', open: true, message: 'Az épület nincs beállító üzemmódban' })
                        );
                        return;
                    }
                } catch (err) {
                    dispatch(
                        setToast({ severity: 'error', open: true, message: 'Az épület nincs beállító üzemmódban' })
                    );
                    return;
                }
            }
            buildingToSave.userEmails = buildingToSave.userEmails.filter((email) => email !== user.email);
            await dispatch(addBuilding(buildingToSave));
            buildingToSave.userEmails.forEach((u) => {
                dispatch(inviteUserToControlBuilding(buildingToSave.source, u));
            });
            if (!error && !loading) {
                navigate(-1);
            }
        }
        if (isAdmin(user) && changed) {
            dispatch(changeSubscription({ subscription: subscriptionType, source: buildingToSave.source }));
        }
    };

    const handleChange = (value, key) => {
        setChanged(true);
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState, mainModuleType),
                value,
            },
        }));
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                            }}
                        >
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.name}
                                            helperText={formState.name.error && formState.name.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.name.name)}
                                            onChange={(event) => handleChange(event.target.value, formState.name.name)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.address}
                                            helperText={formState.address.error && formState.address.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.address.name)}
                                            onChange={(event) =>
                                                handleChange(event.target.value, formState.address.name)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="mainModuleType">Modul típusa</InputLabel>
                                            <Select
                                                labelId="mainModuleType"
                                                id="mainModuleType"
                                                value={mainModuleType}
                                                label="Modul típusa"
                                                onChange={(event) => {
                                                    setMainModuleType(event.target.value);
                                                    setChanged(true);
                                                }}
                                            >
                                                <MenuItem key="LAN" value="LAN">
                                                    Lan
                                                </MenuItem>
                                                <MenuItem key="NECTIVE_HUB" value="NECTIVE_HUB">
                                                    Nective Hub
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {isAdmin(user) && (
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <FormControl required fullWidth>
                                                <InputLabel id="subscriptionTypeLabe">Feliratkozás típusa</InputLabel>
                                                <Select
                                                    labelId="subscriptionTypeLabe"
                                                    id="subscriptionType"
                                                    value={subscriptionType}
                                                    label="Feliratkozás típusa"
                                                    onChange={(event) => {
                                                        setSubscriptionType(event.target.value);
                                                        setChanged(true);
                                                    }}
                                                >
                                                    <MenuItem key="FREE" value="FREE">
                                                        FREE
                                                    </MenuItem>
                                                    <MenuItem key="BMS" value="BMS">
                                                        BMS
                                                    </MenuItem>
                                                    <MenuItem key="GOLD" value="GOLD">
                                                        GOLD
                                                    </MenuItem>
                                                    <MenuItem key="SILVER" value="SILVER">
                                                        SILVER
                                                    </MenuItem>
                                                    <MenuItem key="PLATINA" value="PLATINA">
                                                        PLATINA
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        {reader && (
                                            <QrReader
                                                onResult={(result, error) => {
                                                    if (!!result) {
                                                        handleChange(result?.text, formState.source.name);
                                                        setReader(false);
                                                    }
                                                    if (!!error) {
                                                        dispatch(
                                                            setToast({
                                                                severity: 'error',
                                                                open: true,
                                                                message: 'Valami gond van a kamerával',
                                                            })
                                                        );
                                                    }
                                                }}
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setReader((reader) => !reader)}
                                                            className="material-icons"
                                                        >
                                                            qr_code_scanner
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...formState.source}
                                            helperText={formState.source.error && formState.source.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.source.name)}
                                            onChange={(event) =>
                                                handleChange(event.target.value, formState.source.name)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl required fullWidth>
                                            <InputLabel id="zoneOffset">Időzóna</InputLabel>
                                            <Select
                                                labelId="zoneOffset"
                                                id="zoneOffset"
                                                value={zoneOffset}
                                                label="Időzóna"
                                                onChange={(event) => {
                                                    setZoneOffset(event.target.value);
                                                    setChanged(true);
                                                }}
                                            >
                                                {TIME_ZONE_LIST.map((tz) => (
                                                    <MenuItem key={tz.id} value={tz.value}>
                                                        {tz.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={8}>
                                        <BuildingUsersTable
                                            users={userList}
                                            icons={{ Delete: () => <DeleteOutline color="secondary" /> }}
                                            actions={
                                                edit
                                                    ? [
                                                          (rowData) => ({
                                                              icon: () => (
                                                                  <Star
                                                                      sx={{
                                                                          color:
                                                                              rowData.mainUser || rowData.banned
                                                                                  ? ''
                                                                                  : 'gold',
                                                                      }}
                                                                  />
                                                              ),
                                                              tooltip: 'Kinevezés főfelhasználónak',
                                                              onClick: () => {
                                                                  const userListCopy = [...userList];
                                                                  const currentMainUser = userListCopy.find(
                                                                      (u) => u.mainUser
                                                                  );
                                                                  currentMainUser.mainUser = false;
                                                                  const thisUser = userListCopy.find(
                                                                      (u) => u.email === rowData.email
                                                                  );
                                                                  thisUser.mainUser = true;
                                                                  setUserList(userListCopy);
                                                                  setChanged(true);
                                                              },
                                                              disabled: rowData.mainUser || rowData.banned,
                                                          }),
                                                          (rowData) => ({
                                                              icon: () =>
                                                                  rowData.banned ? (
                                                                      <Check
                                                                          sx={{
                                                                              color: rowData.mainUser ? '' : 'green',
                                                                          }}
                                                                      />
                                                                  ) : (
                                                                      <Block
                                                                          sx={{ color: rowData.mainUser ? '' : 'red' }}
                                                                      />
                                                                  ),
                                                              tooltip: rowData.banned ? 'Tiltás feloldása' : 'Letiltás',
                                                              onClick: () => {
                                                                  const userListCopy = [...userList];
                                                                  const thisUser = userListCopy.find(
                                                                      (u) => u.email === rowData.email
                                                                  );
                                                                  thisUser.banned = !rowData.banned;
                                                                  setUserList(userListCopy);
                                                                  setChanged(true);
                                                              },
                                                              disabled: rowData.mainUser,
                                                          }),
                                                      ]
                                                    : []
                                            }
                                            editable={{
                                                isDeletable: (rowData) =>
                                                    rowData.email !== userList.find((u) => u.mainUser)?.email,
                                                onRowDelete: (rowData) =>
                                                    new Promise((resolve) => {
                                                        setTimeout(() => {
                                                            const userListCopy = [...userList].filter(
                                                                (u) => u.email !== rowData.email
                                                            );
                                                            setUserList(userListCopy);
                                                            setChanged(true);
                                                            resolve();
                                                        }, 0);
                                                    }),
                                                onRowAdd: (newData) =>
                                                    new Promise(async (resolve, reject) => {
                                                        try {
                                                            const response = await apiAxios.get(
                                                                `/public/is-enabled/${newData.email.toLowerCase()}`
                                                            );
                                                            if (response.data.enabled) {
                                                                const userListCopy = [...userList];
                                                                userListCopy.push(newData);
                                                                setUserList(userListCopy);
                                                                setChanged(true);
                                                                resolve();
                                                            }
                                                            reject('Ismeretlen felhasználó');
                                                        } catch (err) {
                                                            reject('Ismeretlen felhasználó');
                                                        }
                                                    }),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Fab
                    disabled={
                        Object.keys(formState).some((key) => formState[key].error) ||
                        Object.keys(formState).some((key) => formState[key].value.length === 0) ||
                        !changed
                    }
                    variant="extended"
                    sx={{ marginTop: 5 }}
                    color="primary"
                    aria-label="save"
                    onClick={saveHandler}
                >
                    Mentés
                </Fab>
                <Fab
                    variant="extended"
                    sx={{ marginTop: 5, marginLeft: 1 }}
                    color="secondary"
                    aria-label="cancel"
                    onClick={() => navigate(-1)}
                >
                    Mégse
                </Fab>
            </Container>
        </Box>
    );
}
