import { createAction, createReducer } from '@reduxjs/toolkit';

import { updateState } from '../../utils/utilityFunctions';

export const setToast = createAction('toast/showToast', (options) => ({
    payload: { options },
}));

const initialState = {
    severity: 'info',
    open: false,
    message: '',
};

export default createReducer(initialState, (builder) => {
    builder.addCase(setToast, (state, { payload }) => updateState(state, { ...payload.options }));
});
