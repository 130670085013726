import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Grid, Paper, Toolbar } from '@mui/material';
import UsersTable from '../../components/Tables/UsersTable/UsersTable';
import { Check, DeleteOutline } from '@mui/icons-material';
import { enableUser, removeUser } from '../../store/ducks/users';

export default function Users() {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <UsersTable
                                icons={{
                                    Delete: (data) => !data.disabled && <DeleteOutline color="secondary" />,
                                }}
                                title={<h2>Felhasználók</h2>}
                                users={users}
                                editable={{
                                    onRowDelete: (rowData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                dispatch(
                                                    removeUser({ email: rowData.email, enabled: rowData.enabled })
                                                );
                                                resolve();
                                            }, 0);
                                        }),
                                }}
                                actions={[
                                    (rowData) => ({
                                        icon: () => !rowData.enabled && <Check color="primary" />,
                                        tooltip: 'Felhasználó engedélyezés',
                                        onClick: () => dispatch(enableUser(rowData.email)),
                                    }),
                                ]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
