import { createAction, createReducer } from '@reduxjs/toolkit';

import { updateState } from '../../utils/utilityFunctions';

export const updateScriptOutput = createAction('script/updateScriptOutput', ({ stderr, stdout }) => ({
    payload: { stderr, stdout },
}));

export const clearScriptState = createAction('script/clearState');

const initialState = {
    stdout: '',
    stderr: '',
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(updateScriptOutput, (state, { payload }) =>
            updateState(state, { stdout: payload.stdout, stderr: payload.stderr })
        )
        .addCase(clearScriptState, () => initialState);
});
