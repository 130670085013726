import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import PieChart from '../PieChart/PieChart';

function Statistics() {
    const statistics = useSelector((state) => state.statistics.statistics);

    return (
        <>
            <Container>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2} sx={{ paddingTop: 2 }}>
                    <Grid item xs={3}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            Árajánlatot elkezdte
                        </Typography>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            {statistics.started}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            Árajánlatot befejezte
                        </Typography>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            {statistics.ended}
                        </Typography>
                    </Grid>
                </Grid>

                <Container sx={{ width: '100%', height: '20rem' }}>
                    <PieChart
                        data={[
                            {
                                label: 'Rendelés 0-500k',
                                id: '0-500k',
                                value: statistics.lowOrder,
                            },
                            {
                                label: 'Rendelés 500 - 1m',
                                id: '500 - 1m',
                                value: statistics.mediumOrder,
                            },
                            {
                                label: 'Rendelés 1m - 3m',
                                id: '1m - 3m',
                                value: statistics.highOrder,
                            },
                            {
                                label: 'Rendelés 3m >',
                                id: '3m >',
                                value: statistics.veryHighOrder,
                            },
                        ]}
                    />
                </Container>
            </Container>
        </>
    );
}

export default Statistics;
