import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Fab,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Toolbar,
} from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

import Copyright from '../../components/UI/Copyright/Copyright';
import { validator } from '../../utils/validators';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { changePassword } from '../../store/ducks/user';

export const initialState = () => ({
    oldpassword: {
        value: '',
        name: 'oldpassword',
        required: true,
        fullWidth: true,
        id: 'oldpassword',
        label: 'Régi jelszó',
        type: 'password',
        error: false,
        helperText:
            'A jelszónak minimum 6 karakterből kell állnia és tartalmaznia kell legalább egy számot vagy legalább egy nagybetűt',
    },
    password: {
        value: '',
        name: 'password',
        required: true,
        fullWidth: true,
        id: 'password',
        type: 'password',
        label: 'Új jelszó',
        error: false,
        helperText:
            'A jelszónak minimum 6 karakterből kell állnia és tartalmaznia kell legalább egy számot vagy legalább egy nagybetűt',
    },
    repassword: {
        value: '',
        name: 'repassword',
        required: true,
        fullWidth: true,
        id: 'repassword',
        type: 'password',
        label: 'Új jelszó újra',
        error: false,
        helperText: 'A két jelszónak egyeznie kell',
    },
});

export default function User() {
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [formState, setFormState] = useState(initialState());
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const token = queryParams.get('token');

    const saveHandler = async () => {
        dispatch(
            changePassword({
                oldPassword: formState.oldpassword?.value,
                newPassword: formState.password.value,
                email,
                token,
            })
        );
        setFormState(initialState());
        if (email && token) {
            navigate('/');
        }
    };

    const handleChange = (value, key) => {
        if (key !== 'oldpassword') {
            setChanged(true);
        }
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState),
                value,
            },
        }));
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 4,
                            }}
                        >
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                {!token && (
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12} md={8} lg={4}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel htmlFor="outlined-adornment-oldpassword">
                                                    {formState.oldpassword.label}
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-oldpassword"
                                                    {...formState.oldpassword}
                                                    type={showOldPassword ? 'text' : 'password'}
                                                    onBlur={(event) =>
                                                        handleChange(event.target.value, formState.oldpassword.name)
                                                    }
                                                    onChange={(event) =>
                                                        handleChange(event.target.value, formState.oldpassword.name)
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() =>
                                                                    setShowOldPassword((prevState) => !prevState)
                                                                }
                                                                edge="end"
                                                            >
                                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel htmlFor="outlined-adornment-password">
                                                {formState.password.label}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-oldpassword"
                                                {...formState.password}
                                                error={
                                                    formState.password.value.trim().length > 0 &&
                                                    formState.password.error
                                                }
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.password.name)
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword((prevState) => !prevState)}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {formState.password.value.trim().length > 0 && formState.password.error && (
                                                <FormHelperText error>{formState.password.helperText}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel htmlFor="outlined-adornment-repassword">
                                                {formState.repassword.label}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-repassword"
                                                {...formState.repassword}
                                                type={showRePassword ? 'text' : 'password'}
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.repassword.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.repassword.name)
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle repassword visibility"
                                                            onClick={() => setShowRePassword((prevState) => !prevState)}
                                                            edge="end"
                                                        >
                                                            {showRePassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {formState.repassword.error && (
                                                <FormHelperText error>{formState.repassword.helperText}</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Fab
                    disabled={
                        (!token && !formState.oldpassword.value.length) ||
                        formState.password.value !== formState.repassword.value ||
                        Object.keys(formState).some((key) => formState[key].error) ||
                        !changed
                    }
                    variant="extended"
                    sx={{ marginTop: 5 }}
                    color="primary"
                    aria-label="save"
                    onClick={saveHandler}
                >
                    Mentés
                </Fab>
                <Fab
                    variant="extended"
                    sx={{ marginTop: 5, marginLeft: 1 }}
                    color="secondary"
                    aria-label="cancel"
                    onClick={() => (token ? navigate('/') : navigate(-1))}
                >
                    Mégse
                </Fab>
                <Copyright sx={{ mt: 5 }} />
                <GoogleReCaptcha />
            </Container>
        </Box>
    );
}
