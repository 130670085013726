import React, { useEffect, useRef, useState } from 'react';
import TerminalEmulator from 'react-console-emulator';
import { useDispatch, useSelector } from 'react-redux';
import { clearScriptState } from '../../../store/ducks/script';
import { sendMessage } from '../../../store/ducks/ws';
import { WEB_ADMIN } from '../../../utils/constants';

export default function Terminal({ name, currentBuilding }) {
    const { stdout, stderr } = useSelector((state) => state.script);
    const dispatch = useDispatch();
    const terminal = useRef();
    const [commands] = useState({
        echo: {
            description: 'Echo a passed string.',
            usage: 'echo <string>',
            fn: function () {
                scriptRequest(`echo ${Array.from(arguments).join(' ')}`);
            },
        },
        touch: {
            description: 'change file access and modification times',
            usage: 'touch [-A [-][[hh]mm]SS] [-achm] [-r file] [-t [[CC]YY]MMDDhhmm[.SS]] [-d YYYY-MM-DDThh:mm:SS[.frac][tz]] file ...',
            fn: function () {
                scriptRequest(`touch ${Array.from(arguments).join(' ')}`);
            },
        },
        rm: {
            description: `The rm utility attempts to remove the non-directory type files specified on the command line.  If the permissions of the file do not permit
            writing, and the standard input device is a terminal, the user is prompted (on the standard error output) for confirmation.`,
            usage: 'rm [-f | -i] [-dIRrvWx] file ...',
            fn: function () {
                scriptRequest(`rm ${Array.from(arguments).join(' ')}`);
            },
        },
        mv: {
            description: `move files`,
            usage: `In its first form, the mv utility renames the file named by the source operand to the destination path named by the target operand.  This
            form is assumed when the last operand does not name an already existing directory.`,
            fn: function () {
                scriptRequest(`mv ${Array.from(arguments).join(' ')}`);
            },
        },
        tail: {
            description: 'display the last part of a file',
            usage: 'tail [-F | -f | -r] [-q] [-b number | -c number | -n number] [file ...]',
            fn: function () {
                scriptRequest(`tail ${Array.from(arguments).join(' ')}`);
            },
        },
        ls: {
            description: 'list directory contents.',
            usage: 'ls [-@ABCFGHILOPRSTUWabcdefghiklmnopqrstuvwxy1%,] [--color=when] [-D format] [file ...]',
            fn: function () {
                scriptRequest(`ls ${Array.from(arguments).join(' ')}`);
            },
        },
        mkdir: {
            description: 'create new directory',
            usage: 'mkdir <directory name>',
            fn: function () {
                scriptRequest(`mkdir ${Array.from(arguments).join(' ')}`);
            },
        },
        cat: {
            description: 'concatenate and print files.',
            usage: 'cat [-belnstuv] [file ...]',
            fn: function () {
                scriptRequest(`cat ${Array.from(arguments).join(' ')}`);
            },
        },
        ifconfig: {
            description: 'configure network interface parameters',
            usage: 'ifconfig <params>',
            fn: function () {
                scriptRequest(`ifconfig ${Array.from(arguments).join(' ')}`);
            },
        },
        sudo: {
            description: 'execute a command as another user',
            usage: 'sudo <command>',
            fn: function () {
                scriptRequest(`sudo ${Array.from(arguments).join(' ')}`);
            },
        },
        pm2: {
            description: 'pm2 node',
            usage: 'pm2 <command>',
            fn: function () {
                scriptRequest(`pm2 ${Array.from(arguments).join(' ')}`);
            },
        },
        run: {
            description: 'Run a passed script on nectiveHub.',
            usage: 'run <script>',
            fn: function () {
                scriptRequest(`${Array.from(arguments).join(' ')}`);
            },
        },
    });

    useEffect(() => {
        if (stdout || stderr) {
            terminal.current.pushToStdout(stdout || stderr);
            dispatch(clearScriptState());
        }
    }, [stdout, stderr, dispatch]);

    const scriptRequest = (script) => {
        console.log(script);
        dispatch(
            sendMessage({
                source: WEB_ADMIN,
                destination: currentBuilding.source,
                messageType: 'SCRIPT_REQUEST',
                isNectiveHub: true,
                script,
            })
        );
    };

    return (
        <TerminalEmulator
            ref={terminal}
            style={{ backgroundColor: '#000' }}
            noAutomaticStdout
            commands={commands}
            welcomeMessage={'Nectivehub scripting emulator'}
            promptLabel={`${name}@${currentBuilding.source}:~$`}
            promptLabelStyle={{ color: '#3ac6ff' }}
            inputTextStyle={{ color: '#3ac6ff' }}
            contentStyle={{ color: '#3ac6ff' }}
            messageStyle={{ color: '#39ff14' }}
        />
    );
}
