import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import * as WebSocket from 'isomorphic-ws';

import envs from '../../config';
import { updateState } from '../../utils/utilityFunctions';
import { buildingUpdateFailed, buildingUpdateState, buildingUpdateSuccess } from './buildings';
import { updateScriptOutput } from './script';
import { setToast } from './toast';

let ws;

export const sendMessage = createAction('ws/sendMessage', (message) => ({ payload: { message } }));

export const disconnect = createAction('ws/disconnect');

export const openConnection = createAsyncThunk('ws/openConnection', async (_, thunkAPI) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
        thunkAPI.dispatch(websocket());
    }
});

export const closeConnection = createAsyncThunk('ws/closeConnection', async (_, thunkAPI) => {
    if (ws) {
        thunkAPI.dispatch(disconnect());
        ws.close();
        ws = null;
    }
});

export const setConnected = createAction('ws/setConnected', (connected) => ({
    payload: { connected },
}));

export const websocket = createAsyncThunk('ws/websocket', async (_, thunkAPI) => {
    ws = new WebSocket(envs.broker);
    ws.onopen = () => thunkAPI.dispatch(setConnected(true));
    ws.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        if (message.messageType === 'UPDATE_STATUS') {
            thunkAPI.dispatch(buildingUpdateState(message.updateStatus));
        } else if (message.messageType === 'UPDATE_SUCCESSFUL') {
            thunkAPI.dispatch(buildingUpdateSuccess());
        } else if (message.messageType === 'UPDATE_FAILED') {
            thunkAPI.dispatch(buildingUpdateFailed(message.errorMessage));
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'A frissítés nem sikerült!',
                })
            );
        } else if (message.messageType === 'SCRIPT_RESPONSE') {
            thunkAPI.dispatch(updateScriptOutput({ stdout: message.stdout, stderr: message.stderr }));
        }
    };
    ws.onclose = () => {
        thunkAPI.dispatch(disconnect());
    };
});

const initialState = {
    connecting: false,
    connected: false,
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(sendMessage, (_, { payload }) => {
            const tokenString = localStorage.getItem('token');
            const token = JSON.parse(tokenString).value;
            ws.send(JSON.stringify({ ...payload.message, token }));
        })
        .addCase(disconnect, (state) => updateState(state, { connected: false, connecting: false }))
        .addCase(setConnected, (state, { payload }) => updateState(state, { connected: payload.connected }))
        .addMatcher(
            (action) => action.type.startsWith('ws') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, connecting: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('ws') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, connecting: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('ws') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, connecting: false, connected: false })
        );
});
