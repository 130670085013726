import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Paper, TextField, Fab, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { validator } from '../../../utils/validators';
import { ANNOUNCEMENT_TYPES } from '../../../utils/constants';
import { mapAnnouncementToServer } from '../../../utils/utilityFunctions';
import { createNewAnnouncement, deleteAnnouncement, updateAnnouncement } from '../../../store/ducks/announcements';
import AlertDialog from '../../../components/UI/AlertDialog/AlertDialog';

const initialState = (announcement) => ({
    titleHu: {
        value: announcement?.titleHu || '',
        name: 'titleHu',
        required: true,
        fullWidth: true,
        id: 'titleHu',
        label: 'Cím magyar',
        error: false,
        helperText: 'A címnek 2-50 karakter hosszúnak kell lennie',
    },
    titleEn: {
        value: announcement?.titleEn || '',
        name: 'titleEn',
        required: false,
        fullWidth: true,
        id: 'titleEn',
        label: 'Cím angol',
        error: false,
        helperText: 'A címnek 0-50 karakter hosszúnak kell lennie',
    },
    titleDe: {
        value: announcement?.titleDe || '',
        name: 'titleDe',
        required: false,
        fullWidth: true,
        id: 'titleDe',
        label: 'Cím német',
        error: false,
        helperText: 'A címnek 0-50 karakter hosszúnak kell lennie',
    },

    descriptionHu: {
        value: announcement?.descriptionHu || '',
        name: 'descriptionHu',
        required: true,
        fullWidth: true,
        id: 'descriptionHu',
        label: 'Leírás magyar',
        error: false,
        helperText: 'A leírásnak 2-500 karakter hosszúnak kell lennie',
    },
    descriptionEn: {
        value: announcement?.descriptionEn || '',
        name: 'descriptionEn',
        required: false,
        fullWidth: true,
        id: 'descriptionEn',
        label: 'Leírás angol',
        error: false,
        helperText: 'A leírásnak 0-500 karakter hosszúnak kell lennie',
    },
    descriptionDe: {
        value: announcement?.descriptionDe || '',
        name: 'descriptionDe',
        required: false,
        fullWidth: true,
        id: 'descriptionDe',
        label: 'Leírás német',
        error: false,
        helperText: 'A leírásnak 0-500 karakter hosszúnak kell lennie',
    },
    link: {
        value: announcement?.link || '',
        name: 'link',
        required: false,
        fullWidth: true,
        id: 'link',
        label: 'Link',
        error: false,
        helperText: 'A linknek 0-500 karakter hosszúnak kell lennie',
    },
});

export default function Announcement({ announcement, dispatch, setShowCreateAnnouncement }) {
    const isNewAnnoncement = !announcement.id;
    const [changed, setChanged] = useState(false);
    const [formState, setFormState] = useState(initialState(announcement));
    const [announcementType, setAnnouncementType] = useState(announcement?.announcementType || 'INFO');
    const [startDate, setStartDate] = useState(
        announcement?.startDate || new Date(Date.now() + 0).setHours(0, 1, 0, 0)
    );
    const [expireAt, setExpireAt] = useState(
        announcement?.expireAt || new Date(Date.now() + 7 * 24 * 3600 * 1000).setHours(23, 59, 0, 0)
    );
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleChange = (value, key) => {
        setChanged(true);
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState),
                value,
            },
        }));
    };

    const saveHandler = () => {
        const announcementToDispatch = mapAnnouncementToServer({
            id: announcement?.id,
            titleHu: formState.titleHu.value,
            titleEn: formState.titleEn.value,
            titleDe: formState.titleDe.value,
            descriptionHu: formState.descriptionHu.value,
            descriptionEn: formState.descriptionEn.value,
            descriptionDe: formState.descriptionDe.value,
            link: formState.link.value,
            announcementType,
            startDate,
            expireAt,
        });
        isNewAnnoncement
            ? dispatch(createNewAnnouncement(announcementToDispatch))
            : dispatch(updateAnnouncement(announcementToDispatch));
        setShowCreateAnnouncement(false);
    };

    return (
        <>
            <AlertDialog
                title={`${isNewAnnoncement ? 'Közzététel' : 'Módosítás'} megerősítése`}
                description={`Biztos, hogy ${
                    isNewAnnoncement ? 'közzéteszed' : 'módosítod'
                } a közleményt? A kezdő dátumtól minden felhasználónak meg fog jelenni`}
                open={saveDialogOpen}
                onClose={() => {
                    setSaveDialogOpen(false);
                }}
                onAgree={() => {
                    saveHandler();
                    setSaveDialogOpen(false);
                    setChanged(false);
                }}
            />
            <AlertDialog
                title="Törlés megerősítése"
                description={`Biztos, hogy törlöd a közleményt? `}
                open={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
                onAgree={() => {
                    dispatch(deleteAnnouncement(announcement.id));
                    setDeleteDialogOpen(false);
                }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 4,
                        }}
                    >
                        <h2>{isNewAnnoncement ? 'Új közlemény' : 'Közlemény'}</h2>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={2} p={0}>
                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="announcementType">Típus</InputLabel>
                                                <Select
                                                    autoComplete="given-announcementType"
                                                    labelId="announcementType"
                                                    id="announcementType-select"
                                                    value={announcementType}
                                                    label="Típus"
                                                    onChange={(event) => {
                                                        setAnnouncementType(event.target.value);
                                                        setChanged(true);
                                                    }}
                                                >
                                                    <MenuItem key="info" value="INFO">
                                                        {ANNOUNCEMENT_TYPES.INFO}
                                                    </MenuItem>
                                                    <MenuItem key="warning" value="WARNING">
                                                        {ANNOUNCEMENT_TYPES.WARNING}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.titleHu}
                                                helperText={formState.titleHu.error && formState.titleHu.helperText}
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.titleHu.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.titleHu.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.descriptionHu}
                                                helperText={
                                                    formState.descriptionHu.error && formState.descriptionHu.helperText
                                                }
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.descriptionHu.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.descriptionHu.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                fullWidth
                                                label="Mettől"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setChanged(true);
                                                    setStartDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                fullWidth
                                                label="Meddig"
                                                value={expireAt}
                                                onChange={(newValue) => {
                                                    setChanged(true);
                                                    setExpireAt(newValue);
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.titleEn}
                                                helperText={formState.titleEn.error && formState.titleEn.helperText}
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.titleEn.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.titleEn.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.titleDe}
                                                helperText={formState.titleDe.error && formState.titleDe.helperText}
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.titleDe.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.titleDe.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.descriptionEn}
                                                helperText={
                                                    formState.descriptionEn.error && formState.descriptionEn.helperText
                                                }
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.descriptionEn.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.descriptionEn.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.descriptionDe}
                                                helperText={
                                                    formState.descriptionDe.error && formState.descriptionDe.helperText
                                                }
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.descriptionDe.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.descriptionDe.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} p={1}>
                                        <Grid item xs={12}>
                                            <TextField
                                                {...formState.link}
                                                helperText={formState.link.error && formState.link.helperText}
                                                onBlur={(event) =>
                                                    handleChange(event.target.value, formState.link.name)
                                                }
                                                onChange={(event) =>
                                                    handleChange(event.target.value, formState.link.name)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <>
                <Fab
                    disabled={
                        Object.keys(formState).some((key) => formState[key].error && formState[key].required) ||
                        !changed ||
                        !formState.titleHu.value ||
                        !formState.descriptionHu.value
                    }
                    variant="extended"
                    sx={{ marginTop: 3, marginBottom: 6 }}
                    color="primary"
                    aria-label="save"
                    onClick={() => setSaveDialogOpen(true)}
                >
                    {isNewAnnoncement ? 'Létrehozás' : 'Módosítás'}
                </Fab>
                <Fab
                    variant="extended"
                    sx={{ marginTop: 3, marginBottom: 6, marginLeft: 1 }}
                    color="secondary"
                    aria-label="cancel"
                    onClick={
                        isNewAnnoncement ? () => setShowCreateAnnouncement(false) : () => setDeleteDialogOpen(true)
                    }
                >
                    {isNewAnnoncement ? 'Mégse' : 'Törlés'}
                </Fab>
            </>
        </>
    );
}
