import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Paper } from '@mui/material';

import Statistics from '../../components/Statistics/Statistics';

export default function StatisticsPage() {
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Toolbar />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Statistics />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
