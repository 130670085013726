import React from 'react';
import { useSelector } from 'react-redux';

import Table from '../../UI/Table/Table';

function NectiveHubsTable({ ...tableProps }) {
    const nectiveHubs = useSelector((state) => state.nectiveHub.nectiveHubs);

    const columns = [
        {
            title: 'NectiveHub azonosító',
            field: 'source',
        },
        {
            title: 'Épület neve',
            render: (rowData) => rowData.building?.name || 'nincs beépítve',
            editable: 'never',
        },
        { title: 'Regisztrálás ideje', field: 'created', type: 'date', editable: 'never' },
        { title: 'Regisztráló admin', field: 'creator', editable: 'never' },
        { title: 'Törölt', field: 'del', type: 'boolean', editable: 'never' },
    ];

    return (
        <Table
            title={<h2>Nective Hubok</h2>}
            columns={columns}
            data={nectiveHubs.map((nh) => ({ ...nh, id: nh._id }))}
            {...tableProps}
        />
    );
}
export default NectiveHubsTable;
