import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import enLocale from 'date-fns/locale/en-US';
import huLocale from 'date-fns/locale/hu';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import moment from 'moment';
import 'moment/locale/hu';

import App from './App';
import ColorModeProvider from './context/ColorModeContext';
import * as serviceWorker from './serviceWorker';
import envs from './config/index';
import configureStore from './store';

moment.locale('hu');

axios.defaults.baseURL = envs.authUrl;

const localeMap = {
    en: enLocale,
    hu: huLocale,
};

axios.interceptors.request.use(
    (config) => {
        config.headers.authorization = (JSON.parse(localStorage.getItem('token')) || {}).value;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <ColorModeProvider>
            <GoogleReCaptchaProvider
                reCaptchaKey={envs.reCaptchaId}
                language="hu"
                useRecaptchaNet={true}
                useEnterprise={true}
                scriptProps={{
                    async: true,
                    defer: true,
                    appendTo: 'body',
                }}
            >
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap['hu']}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </LocalizationProvider>
                </Provider>
            </GoogleReCaptchaProvider>
        </ColorModeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
