import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Toolbar,
} from '@mui/material';
import { validator } from '../../utils/validators';
import { DatePicker } from '@mui/lab';
import { updateUser } from '../../store/ducks/user';
import { SyncLock } from '@mui/icons-material';

export const initialState = (user) => ({
    name: {
        value: user?.name || '',
        name: 'name',
        required: true,
        fullWidth: true,
        id: 'name',
        label: 'Név',
        error: false,
        helperText: 'A névnek legalább 2 karakter hosszúnak kell lennie',
    },
    email: {
        value: user?.email || '',
        name: 'email',
        required: true,
        fullWidth: true,
        id: 'email',
        label: 'Email cím',
        error: false,
        helperText: 'Helytelen email',
    },
    city: {
        value: user?.city || '',
        name: 'city',
        required: true,
        fullWidth: true,
        id: 'city',
        label: 'Város',
        error: false,
        helperText: 'A városnak legalább 3 karakter hosszúnak kell lennie',
    },
    zipCode: {
        value: user?.zipCode || '',
        name: 'zipCode',
        required: true,
        fullWidth: true,
        id: 'zipCode',
        label: 'Irányítószám',
        error: false,
        helperText: 'Az irányító számnak legalább 4 karakter hosszúnak kell lennie',
    },
});

export default function User() {
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [formState, setFormState] = useState(initialState(user));
    const [country, setCountry] = useState(user.country);
    const [birthDate, setBirthDate] = useState(user.birthDate);
    const [gender, setGender] = useState(user.gender);

    useEffect(() => {
        if (user) {
            setFormState(initialState(user));
        }
    }, [user]);

    const saveHandler = async () => {
        const userToUpdate = {
            name: formState.name.value,
            email: formState.email.value.toLowerCase(),
            city: formState.city.value,
            zipCode: formState.zipCode.value,
            gender,
            country,
            birthDate,
        };
        dispatch(updateUser(userToUpdate));
    };

    const handleChange = (value, key) => {
        if (key !== 'oldpassword') {
            setChanged(true);
        }
        setFormState((oldFormState) => ({
            ...oldFormState,
            [key]: {
                ...oldFormState[key],
                error: validator(key, value, formState),
                value,
            },
        }));
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 4,
                            }}
                        >
                            <Box component="form" noValidate sx={{ mt: 1 }}>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.name}
                                            helperText={formState.name.error && formState.name.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.name.name)}
                                            onChange={(event) => handleChange(event.target.value, formState.name.name)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.email}
                                            helperText={formState.email.error && formState.email.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.email.name)}
                                            onChange={(event) => handleChange(event.target.value, formState.email.name)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="country">Ország</InputLabel>
                                            <Select
                                                autoComplete="given-country"
                                                labelId="country"
                                                id="country-select"
                                                value={country}
                                                label="Ország"
                                                onChange={(event) => {
                                                    setCountry(event.target.value);
                                                    setChanged(true);
                                                }}
                                            >
                                                <MenuItem key="hungary" value="HUNGARY">
                                                    Magyarország
                                                </MenuItem>
                                                <MenuItem key="germany" value="GERMANY">
                                                    Németország
                                                </MenuItem>
                                                <MenuItem key="england" value="ENGLAND">
                                                    Anglia
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.city}
                                            helperText={formState.city.error && formState.city.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.city.name)}
                                            onChange={(event) => handleChange(event.target.value, formState.city.name)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <TextField
                                            {...formState.zipCode}
                                            helperText={formState.zipCode.error && formState.zipCode.helperText}
                                            onBlur={(event) => handleChange(event.target.value, formState.zipCode.name)}
                                            onChange={(event) =>
                                                handleChange(event.target.value, formState.zipCode.name)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <DatePicker
                                            fullWidth
                                            label="Születési dátum"
                                            value={birthDate}
                                            onChange={(newValue) => {
                                                setChanged(true);
                                                setBirthDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} p={1}>
                                    <Grid item xs={12} md={8} lg={4}>
                                        <FormControl>
                                            <FormLabel id="gender">Neme</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="gender"
                                                defaultValue="female"
                                                name="gender-select"
                                                onChange={(event) => {
                                                    setChanged(event.target.value !== user.gender);
                                                    setGender(event.target.value);
                                                }}
                                                value={gender}
                                            >
                                                <FormControlLabel value="FEMALE" control={<Radio />} label="Nő" />
                                                <FormControlLabel value="MALE" control={<Radio />} label="Férfi" />
                                                <FormControlLabel value="OTHER" control={<Radio />} label="Egyéb" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Button
                                    onClick={() => navigate(`/change-password-user`)}
                                    variant="contained"
                                    startIcon={<SyncLock />}
                                >
                                    Jelszó módosítás
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Fab
                    disabled={Object.keys(formState).some((key) => formState[key].error) || !changed}
                    variant="extended"
                    sx={{ marginTop: 5 }}
                    color="primary"
                    aria-label="save"
                    onClick={saveHandler}
                >
                    Mentés
                </Fab>
                <Fab
                    variant="extended"
                    sx={{ marginTop: 5, marginLeft: 1 }}
                    color="secondary"
                    aria-label="cancel"
                    onClick={() => navigate(-1)}
                >
                    Mégse
                </Fab>
            </Container>
        </Box>
    );
}
