import { Navigate, Outlet } from 'react-router-dom';
import { isAdmin } from '../../utils/utilityFunctions';

const AdminRoute = ({ user, redirectPath = '/loading' }) => {
    if (!isAdmin(user?.user)) {
        return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
};
export default AdminRoute;
