import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getPriceList = createAsyncThunk('priceList/getPriceList', async (_, thunkAPI) => {
    try {
        const response = await apiAxios.get('/public/prices');
        return { priceList: response.data };
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az árlista lekérése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

export const updatePriceList = createAsyncThunk('priceList/updatePriceList', async (priceList, thunkAPI) => {
    try {
        await apiAxios.put('/admin/prices', priceList);
        thunkAPI.dispatch(getPriceList());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült az árlista frissítése',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    priceList: {},
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getPriceList.fulfilled, (state, { payload }) => updateState(state, { priceList: payload.priceList }))
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('priceList') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false, authenticated: false })
        );
});
