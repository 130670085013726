import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import apiAxios from '../../axios/apiAxios';
import { updateState } from '../../utils/utilityFunctions';
import { setToast } from './toast';

export const getAvailableAnnouncements = createAsyncThunk(
    'announcements/getAvailableAnnouncements',
    async (_, thunkAPI) => {
        try {
            const response = await apiAxios.get('/user/announcements');
            return { announcements: response.data };
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const createNewAnnouncement = createAsyncThunk(
    'announcements/createNewAnnouncement',
    async (announcement, thunkAPI) => {
        try {
            await apiAxios.post(`/admin/announcement`, announcement);
            thunkAPI.dispatch(
                setToast({
                    severity: 'success',
                    open: true,
                    message: 'Sikeresen létrehoztad a közleményt',
                })
            );
            thunkAPI.dispatch(getAvailableAnnouncements());
        } catch (err) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült létrehozni a közleményt',
                })
            );
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const updateAnnouncement = createAsyncThunk(
    'announcements/updateAnnouncement',
    async (announcement, thunkAPI) => {
        try {
            await apiAxios.put(`/admin/announcement/${announcement.id}`, announcement);
            thunkAPI.dispatch(
                setToast({
                    severity: 'success',
                    open: true,
                    message: 'Sikeresen módosítottad a közleményt',
                })
            );
            thunkAPI.dispatch(getAvailableAnnouncements());
        } catch (err) {
            thunkAPI.dispatch(
                setToast({
                    severity: 'error',
                    open: true,
                    message: 'Nem sikerült módosítani a közleményt',
                })
            );
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const deleteAnnouncement = createAsyncThunk('announcements/deleteAnnouncement', async (id, thunkAPI) => {
    try {
        await apiAxios.delete(`/admin/announcement/${id}`);
        thunkAPI.dispatch(
            setToast({
                severity: 'success',
                open: true,
                message: 'Sikeresen törölted a közleményt',
            })
        );
        thunkAPI.dispatch(getAvailableAnnouncements());
    } catch (err) {
        thunkAPI.dispatch(
            setToast({
                severity: 'error',
                open: true,
                message: 'Nem sikerült törölni a közleményt',
            })
        );
        return thunkAPI.rejectWithValue(err.response.data);
    }
});

const initialState = {
    loading: false,
    error: null,
    announcements: [],
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(getAvailableAnnouncements.fulfilled, (state, { payload }) =>
            updateState(state, { announcements: payload.announcements })
        )
        .addMatcher(
            (action) => action.type.startsWith('announcements') && action.type.endsWith('/pending'),
            (state) => updateState(state, { error: null, loading: true })
        )
        .addMatcher(
            (action) => action.type.startsWith('announcements') && action.type.endsWith('/fulfilled'),
            (state) => updateState(state, { error: null, loading: false })
        )
        .addMatcher(
            (action) => action.type.startsWith('announcements') && action.type.endsWith('/rejected'),
            (state, action) => updateState(state, { error: action.payload, loading: false })
        );
});
