import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Avatar,
    Box,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Toolbar,
    Tooltip,
    Menu,
    MenuItem,
} from '@mui/material';
import { NotificationsActive, Notifications as NotificationIcon, ClearAll } from '@mui/icons-material';
import moment from 'moment';
import { readNotification, deleteAllNotification, readAllNotifications } from '../../store/ducks/user';

export default function Notifications() {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            {user.sentNotifications.length > 0 && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {user.sentNotifications.filter((n) => !n.read).length > 0 && (
                        <MenuItem
                            onClick={() => {
                                dispatch(readAllNotifications());
                                handleClose();
                            }}
                        >
                            Összeset olvasottnak jelöl
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            dispatch(deleteAllNotification());
                            handleClose();
                        }}
                    >
                        Összeset töröl
                    </MenuItem>
                </Menu>
            )}
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12}>
                        <Paper
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                            }}
                        >
                            <ClearAll
                                sx={{ position: 'absolute', right: 20, cursor: 'pointer' }}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            />
                            <List sx={{ width: '100%', maxWidth: 580, bgcolor: 'background.paper' }}>
                                {[...user.sentNotifications].reverse().map((noti) => (
                                    <>
                                        <Tooltip key={noti.id} title={noti.read ? 'Olvasott' : 'Olvasottnak jelöl'}>
                                            <ListItem
                                                key={noti.id}
                                                sx={{ width: '100%', cursor: !noti.read ? 'pointer' : 'default' }}
                                                onClick={
                                                    noti.read ? (_) => {} : () => dispatch(readNotification(noti.id))
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {noti.read ? (
                                                            <NotificationIcon />
                                                        ) : (
                                                            <NotificationsActive color="secondary" />
                                                        )}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{ width: '100%', color: noti.read ? 'gray' : 'inherit' }}
                                                    primary={noti.message}
                                                    secondary={moment(noti.created).fromNow()}
                                                />
                                            </ListItem>
                                        </Tooltip>
                                        <Divider variant="inset" component="li" />
                                    </>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
