import axios from 'axios';
import envs from '../config/index';

const apiAxios = axios.create({ baseURL: envs.apiUrl });

apiAxios.interceptors.request.use(
    (config) => {
        config.headers.authorization = (JSON.parse(localStorage.getItem('token')) || {}).value;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiAxios;
